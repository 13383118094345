import {
    createEntityAdapter, createSlice, PayloadAction,
} from '@reduxjs/toolkit';
import { StateSchema } from 'app/providers/StoreProvider';
import {
    addAdditionalAddress,
} from '../../services/addAdditionalAddress/addAdditionalAddress';
import {
    deleteAdditionalAddress,
} from '../../services/deleteAdditionalAddress/deleteAdditionalAddress';
import {
    fetchAdditionalAddresses,
} from '../../services/fetchAdditionalAddresses/fetchAdditionalAddresses';
import {
    verificationAddressByPhoto,
} from '../../services/verificationAddressByPhoto/verificationAddressByPhoto';
import { AdditionalAddressSchema } from '../../types/AdditionalAddressSchema';
import { IAdditionalAddress } from '../../types/IAdditionalAddress';

const initialState: AdditionalAddressSchema = {
    data: [],
    isLoading: false,
    error: undefined,
    ids: [],
    entities: {},
};

export const additionalAddressesAdapter = createEntityAdapter<IAdditionalAddress>({
    selectId: (address) => address.id,
});

export const getAddresses = additionalAddressesAdapter.getSelectors<StateSchema>(
    (state) => state.additionalAddress || additionalAddressesAdapter.getInitialState(),
);

const additionalAddressSlice = createSlice({
    name: 'additionalAddress',
    initialState: additionalAddressesAdapter.getInitialState<AdditionalAddressSchema>(initialState),
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchAdditionalAddresses.pending, (state) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(fetchAdditionalAddresses.fulfilled, (state, action: PayloadAction<IAdditionalAddress[]>) => {
                state.isLoading = false;
                additionalAddressesAdapter.setMany(state, action.payload);
            })
            .addCase(fetchAdditionalAddresses.rejected, (state, action) => {
                state.error = 'error';
                state.isLoading = false;
            })
            .addCase(addAdditionalAddress.pending, (state) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(addAdditionalAddress.fulfilled, (state, action: PayloadAction<IAdditionalAddress>) => {
                state.isLoading = false;
                state.data = [action.payload, ...state.data];
                additionalAddressesAdapter.addOne(state, action.payload);
            })
            .addCase(addAdditionalAddress.rejected, (state, action) => {
                state.error = action.payload;
                state.isLoading = false;
            })
            .addCase(deleteAdditionalAddress.pending, (state) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(deleteAdditionalAddress.fulfilled, (state, action: PayloadAction<number>) => {
                state.isLoading = false;
                additionalAddressesAdapter.removeOne(state, action.payload);
            })
            .addCase(deleteAdditionalAddress.rejected, (state, action) => {
                state.error = action.payload;
                state.isLoading = false;
            })
            .addCase(verificationAddressByPhoto.pending, (state) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(verificationAddressByPhoto.fulfilled, (state, action: PayloadAction<IAdditionalAddress>) => {
                state.isLoading = false;
                additionalAddressesAdapter.updateOne(state, { id: action.payload.id, changes: action.payload });
            })
            .addCase(verificationAddressByPhoto.rejected, (state, action) => {
                state.error = action.payload;
                state.isLoading = false;
            });
    },
});

export const { actions: additionalAddressActions, reducer: additionalAddressReducer } = additionalAddressSlice;
