import React, { useEffect, useRef } from 'react';
import useDeviceDetect from 'shared/hooks/useDeviceDetect/useDeviceDetect';

interface YandexShareProps {
    cam: string;
    cameraName: string;
}

const YandexShare: React.FC<YandexShareProps> = ({ cam, cameraName }) => {
    const shareDivRef = useRef<HTMLDivElement>(null);
    const { isMobile } = useDeviceDetect();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://yastatic.net/share2/share.js';
        script.async = true;

        if (shareDivRef.current) {
            shareDivRef.current.appendChild(script);
        }

        return () => {
            // Очистка при размонтировании компонента
            if (shareDivRef.current) {
                shareDivRef.current.innerHTML = '';
            }
        };
    }, []);

    return (
        <div
            ref={shareDivRef}
            className="ya-share2"
            data-lang="ru"
            data-curtain
            data-title={cameraName}
            data-shape="normal"
            data-size="m"
            data-limit="0"
            data-more-button-type={isMobile ? 'short' : 'long'}
            data-copy="first"
            data-services="telegram,vkontakte,whatsapp,viber,messenger,odnoklassniki"
            data-url={cam}
        />
    );
};
export default YandexShare;
