import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthSchema } from '../types/AuthSchema';
import { AuthData } from '../types/AuthData';
import { authLogin } from '../thunks/authLogin/authLogin';
import { ipAuth } from '../thunks/ipAuth/ipAuth';

const initialState: AuthSchema = {
    token: 'empty',
    userID: null,
    isAuth: localStorage.getItem('isLogin') === '1',
    status: 'idle',
    error: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.token = action.payload.token;
            state.userID = action.payload.userID;
            state.isAuth = !!action.payload.token;
        },
        removeUser: (state) => {
            state.token = null;
            state.userID = null;
            state.isAuth = false;
        },
        setAccessToken: (state, action) => {
            state.token = action.payload;
        },
        setAuth: (state, action) => {
            state.isAuth = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(authLogin.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(authLogin.fulfilled, (state, action: PayloadAction<AuthData>) => {
                state.status = 'succeeded';
                state.token = action.payload.accessToken;
                state.userID = action.payload.payload?.phone?.toString();
                state.isAuth = !!action.payload.accessToken;
                state.error = null;
                localStorage.setItem('accToken', action.payload.accessToken);
            })
            .addCase(authLogin.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(ipAuth.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(ipAuth.fulfilled, (state, action: PayloadAction<AuthData>) => {
                state.status = 'succeeded';
                state.token = action.payload.accessToken;
                state.isAuth = !!action.payload.accessToken;
                localStorage.setItem('accToken', action.payload.accessToken);
            })
            .addCase(ipAuth.rejected, (state) => {
                state.status = 'failed';
                state.error = 'Не удалось войти, проверьте соединение.';
            });
        // .addCase(authLogout.fulfilled, (state) => {
        //     state.status = 'succeeded';
        //     this.reducers.removeUser(state);
        // });
    },
});

export const { actions: authActions, reducer: authReducer } = authSlice;
