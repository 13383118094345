import React, { useEffect, useRef, useState } from 'react';
import {
    Box, Button, TextField, Typography,
} from '@mui/material';
import InputMask from 'react-input-mask';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toastr } from 'react-redux-toastr';
import Timer from 'shared/ui/Timer/ui/Timer';
import { useAppDispatch, useAppSelector } from 'app/providers/StoreProvider';
import { getCodeByPhone, selectVerificationData, verifyPhone } from '../../../model';

const schema = yup.object().shape({
    code: yup
        .string()
        .min(4, 'Код должен содержать 4 цифры')
        .max(4, 'Код должен содержать 4 цифры')
        .required(),
});

const CodeConfirmForm = () => {
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
    });
    const [isTimeExp, setTimeExp] = useState(false);
    const [isTimer, setTimer] = useState(false);
    const input = useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch();
    const { phone, error, code } = useAppSelector(selectVerificationData);

    useEffect(() => {
    // @ts-ignore
        const expTime = JSON.parse(localStorage.getItem('verification')).tryDate + 90;
        if (expTime > Date.now()) {
            setTimeExp(false);
        }
        input?.current?.focus();
        setTimer(true);
    }, []);

    useEffect(() => {
        if (isTimeExp) {
            setTimer(false);
        }
    }, [isTimeExp]);

    useEffect(() => {
        if ('OTPCredential' in window) {
            const signal = new AbortController();

            navigator.credentials
                .get({
                    // @ts-ignore
                    otp: { transport: ['sms'] },
                    abort: signal,
                })
                .then((otp) => {
                    // @ts-ignore
                    // eslint-disable-next-line no-undef
                    setOtp(otp?.code);
                    signal.abort();
                })
                .catch((err) => {
                    signal.abort();
                });
        }
    }, []);

    const onSubmit = (data: any) => {
        dispatch(verifyPhone(data))
            .unwrap()
            .catch((error: any) => {
                toastr.error('Error', error.message);
            });
    };

    const requestCode = () => {
        dispatch(getCodeByPhone({ phone }));

        setTimer(true);
        setTimeExp(false);
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ display: 'flex', flexDirection: 'column', gap: 10 }}
        >
            <Typography>Введите 4-х значный код из СМС</Typography>
            <InputMask mask="9999" disabled={false} maskChar="" {...register('code')}>
                {/* @ts-ignore */}
                {() => (
                    <TextField
                        label="Одноразовый код"
                        autoComplete="one-time-code"
                        name="code"
                        /* @ts-ignore */
                        inputMode="tel"
                        type="tel"
                        inputRef={input}
                        error={!!errors.code}
                        /* @ts-ignore */
                        helperText={
                            errors?.code?.message
              || (error === 'wrong code' && `${code} - неправильный код`)
                        }
                    />
                )}
            </InputMask>
            {isTimer && (
                <Box sx={{ m: '0 auto' }}>
                    <span>Повторить отправку: </span>
                    <Timer minutes={1} seconds={30} setTimeExp={setTimeExp} />
                </Box>
            )}
            <Box sx={{ width: '100%', display: 'flex', gap: 2 }}>
                <Button
                    fullWidth
                    disabled={!isValid}
                    variant="contained"
                    type="submit"
                >
                    Подтвердить
                </Button>
                <Button
                    onClick={requestCode}
                    disabled={!isTimeExp}
                    fullWidth
                    variant="contained"
                >
                    Код не пришел
                </Button>
            </Box>
        </form>
    );
};

export default CodeConfirmForm;
