import React from 'react';
import { Typography } from 'shared/ui';
import cls from './NumberInfo.module.scss';

export const NumberInfo = () => (
    <div className={cls.wrapper}>
        <div className={cls.superInstruction}>
            <Typography variant="body" className={cls.superTitle}> Инструкция по входу</Typography>
        </div>
        <Typography variant="body" className={`${cls.title} ${cls.mt}`}>Зачем мы спрашиваем номер телефона?</Typography>

        <Typography gutterBottom variant="body" className={`${cls.text} ${cls.mt}`}>
            Для входа в личный кабинет абонента в приложении введите номер телефона,
            привязанный к вашей учетной записи в личном кабинете на сайте
            {' '}
            <a href="https://baza.net/cabinet" target="_blank" rel="noopener noreferrer">
                http://baza.net/cabinet
            </a>
            .
        </Typography>
        <Typography variant="body" className={cls.text}>
            Если указанного телефона нет в нашей системе - Вы войдете в приложение как гость и получите возможность
            смотреть онлайн-камеры.
        </Typography>

        <Typography variant="body" className={`${cls.title} ${cls.mt}`}>
            Зачем звонить на номер 8-800 для входа?
        </Typography>

        <Typography variant="body" className={`${cls.text} ${cls.mt}`}>
            Необходимо подтвердить, что Вы владелец введенного номера телефона и намерены войти в личный кабинет.
            Для этого нужно пройти процедуру идентификации. Мы определим ваш номер телефона и сразу завершим вызов.
        </Typography>
    </div>
);
