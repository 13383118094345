import React, { HTMLProps } from 'react';
import InputMask from 'react-input-mask';
import cls from './PhoneInput.module.scss';
import { Input } from '../../Input';
import { InputProps } from '../../Input/Input';

interface PhoneInputProps extends InputProps {
  className?: string;
  register?: any;
  disabled?: boolean;
}

export const PhoneInput: React.FC<PhoneInputProps> = React.forwardRef(
    ({
        className, register, disabled = false, ...props
    }: PhoneInputProps, ref) => {
        const rootClasses = [cls.PhoneInput, className].filter(Boolean).join(' ');
        return (
            <InputMask
                mask="+7(999)999-99-99"
                disabled={disabled}
                maskChar=""
                {...props}
                {...register}
                ref={ref}
            >
                {/* @ts-ignore */}
                {(inputProps) => <Input {...inputProps} disabled={disabled} />}
            </InputMask>
        );
    },
);
