import { createSlice } from '@reduxjs/toolkit';
import { LocationState } from '../types/LocationState';
import { fetchLocations } from '../services/fetchLocations';

const initialState: LocationState = {
    isLoading: false,
    data: undefined,
};

export const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        setState: (state, action) => {
            state.data = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchLocations.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchLocations.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload.data;
            })
            .addCase(fetchLocations.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

export const { actions: locationActions, reducer: locationReducer } = locationSlice;
