import React, { HTMLAttributes } from 'react';
import cls from './AppBar.module.scss';

interface AppBarProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  children?: React.ReactElement | React.ReactNode;
}

const AppBar: React.FC<AppBarProps> = ({ className, ...props }) => {
    const rootClasses = [cls.AppBar, className].filter(Boolean).join(' ');
    return <div className={rootClasses} {...props} />;
};

export default AppBar;
