import {
    FC, useEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
    Button, Card, Flex, Typography,
} from 'shared/ui';
import { classNames } from 'shared/lib';
import { useNavigate } from 'react-router-dom';
import {
    PaymentCard, PaymentDollars, PaymentSbp, PaymentYou, 
} from 'shared/assets/images';
import {
    InputAdornment,
    TextField,
} from '@mui/material';
import { IContract } from 'entities/user/model/types';
import { IContractPart, currentContractSelector, profileSelector } from 'entities/user/model';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { payForwardSelector } from 'entities/variables';
import { copyToClipboard } from 'features/CopyToClipboard/copyToClipboard';
import useDeviceDetect from 'shared/hooks/useDeviceDetect/useDeviceDetect';
import { CopyClipboard } from 'shared/assets/icons';
import SetPaymentButton from './SetPaymentButton';
import cls from './PaymentForm.module.scss';
import 'react-toastify/dist/ReactToastify.css';

const schema = yup
    .object()
    .shape({
        payment: yup
            .number()
            .positive()
            .integer()
            .required()
            .max(30000, 'Не больше 30000'),
        email: yup.string().required().email(),
        address_full: yup.string().required(),
        uid: yup.number().positive().integer(),
    })
    .required();
type FormData = yup.InferType<typeof schema>;

interface PaymentFormProps {
  className?: string;
  contract: IContract | any;
  additioalUidContract?: boolean;
  onSubmit: (data: any) => void;
  isActive: boolean;
  onIsActiveChange: () => void;
  onFormDataChange: (data: FormData) => void;
}

export const PaymentForm: FC<PaymentFormProps> = ({
    contract, isActive, onIsActiveChange, additioalUidContract, className = '', onFormDataChange,
}) => {
    const navigate = useNavigate();
    const profile = useSelector(profileSelector);
    const [periodPayment, setPeriodPayment] = useState<number>(0);
    const payForward = useSelector(payForwardSelector(contract.price));
    const [selectedContracts, setSelectedContracts] = useState<IContract[]>([]);
    const [sumPayment, setSumPayment] = useState<number>(contract.recommendedPayment);
    const currentContract = useSelector(currentContractSelector);
    const payImages = [PaymentCard, PaymentSbp, PaymentYou, PaymentDollars];
    const [balls, setBalls] = useState(0);
    const [months, setMonth] = useState(0);
    const { isMobile } = useDeviceDetect();
    const refLink = `https://baza.net/internet?ref=${Math.abs(currentContract?.uid || 0).toString(36)}`;
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            payment: sumPayment || '',
            email: profile?.email,
            address_full: contract.addressFull,
            uid: contract.uid || '',
            oper: contract.oper || '',
        },
        mode: 'onBlur',
        resolver: yupResolver(schema),
    });
    const paymentValue = watch('payment', 0);

    useEffect(() => {
        const payment = Number(paymentValue);
        let calculatedMonths = 0;
        let bonus = 0;

        if (contract.price >= 300 && contract.price <= 750) {
            if (payment >= contract.price * 24) {
                calculatedMonths = 24;
                bonus = 1500;
            } else if (payment >= contract.price * 12) {
                calculatedMonths = 12;
                bonus = 700;
            } else if (payment >= contract.price * 6) {
                calculatedMonths = 6;
                bonus = 250;
            } else if (payment >= contract.price * 3) {
                calculatedMonths = 3;
                bonus = 100;
            }
        } else if (contract.price > 750) {
            if (payment >= contract.price * 24) {
                calculatedMonths = 24;
                bonus = 1700;
            } else if (payment >= contract.price * 12) {
                calculatedMonths = 12;
                bonus = 800;
            } else if (payment >= contract.price * 6) {
                calculatedMonths = 6;
                bonus = 300;
            } else if (payment >= contract.price * 3) {
                calculatedMonths = 3;
                bonus = 150;
            }
        }
        setBalls(bonus);
        setMonth(calculatedMonths);
    }, [paymentValue, contract.price]);
    
    useEffect(() => {
        if (isActive) {
            setValue('payment', sumPayment);
            setValue('uid', currentContract?.uid);
            setValue('address_full', currentContract?.addressFull);
            setValue('oper', currentContract?.oper);
        }
    }, [currentContract, isActive, contract, sumPayment]);

    useEffect(() => {
        setSelectedContracts([]);
    }, []);

    useEffect(() => {
        const addresses = selectedContracts?.map((contract: IContract | IContractPart) => {
            if (contract && 'operTitle' in contract) {
                return {
                    label: contract.address,
                    value: contract.address,
                };
            }
            if (contract && typeof contract.address === 'object') {
                const addressFull = `${contract.address.city}, ${contract.address.street} ${
                    contract.address.home
                }, кв.${contract.address.flat}`;
                return {
                    label: addressFull,
                    value: addressFull,
                };
            }
            return {};
        });
        if (addresses && addresses.length === 1) {
            setValue('address_full', addresses[0].value);
        }
    }, [selectedContracts]);
    
    const onSubmit = async (data: FormData) => {
        const addressData = {
            choosenAddress: currentContract?.addressFull,
            uid: currentContract?.uid,
            oper: currentContract?.oper,
        };
        if (currentContract?.addressFull) { localStorage.setItem('choosenAddress', JSON.stringify(addressData)); }
        navigate('/pay');
        localStorage.setItem('paymentData', JSON.stringify(data));
    };

    useEffect(() => {
        if (isActive) {
            const subscription = watch((data) => {
                onFormDataChange(data as FormData);
            });
            return () => subscription.unsubscribe();
        }
    }, [watch, isActive]);
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Card
                className={`${cls.payForm} ${isActive ? cls.payFormActive : ''}`}
                onClick={onIsActiveChange}
            >
                <div>
                    <div
                        className={cls.contractAddress}
                    >
                        <div className={`${cls.topCardWrapper} ${isActive ? cls.contractAddressActive : ''}`}>
                            <div className={`${cls.circle} ${!isActive && cls.circleActive}`}>
                                <div className={`${cls.circleInCircle} ${!isActive && cls.circleInCircleActive}`} />
                            </div>
                            <Flex direction="column" gap={4}>
                                <div className={cls.dogovor}>
                                    Договор №
                                    {contract.uid}
                                </div>
                                <div className={cls.addressFull}>
                                    {contract.addressFull}
                                </div>
                            </Flex>
                        </div>
                        {!isMobile && (
                            <div className={cls.payForwardSide}>
                                <div className={cls.imagesWrapper}>
                                    {payImages.map((image) => (
                                        <img key={image} src={image} alt="Оплата" />
                                    ))}
                                </div>                    
                            </div>
                        )}
                    </div>
                    {isActive && (
                        <div className={cls.bottomPart}>
                            <div>
                                <div className={cls.tariffName}>
                                    {!additioalUidContract && (
                                        <Typography variant="body">
                                            {`Тариф: ${currentContract?.tariffString ?? ''}`}
                                        </Typography>
                                    )}
                                </div>
                                <div className={cls.emailPay}>
                                    <TextField
                                        id="email"
                                        margin="dense"
                                        placeholder="Email для отправки чека"
                                        label="Email для отправки чека"
                                        variant="filled"
                                        type="email"
                                        defaultValue={profile?.email || ''}
                                        {...register('email')}
                                        sx={{
                                            '& .MuiFilledInput-root': {
                                                backgroundColor: '#F4F4F4',
                                                borderRadius: '8px',
                                                border: '1px solid #49A0B4',
                                                '&:hover': {
                                                    backgroundColor: '#F4F4F4',
                                                },
                                                '&.Mui-focused': {
                                                    backgroundColor: '#F4F4F4',
                                                    border: '1px solid #49A0B4',
                                                },
                                            },
                                            '& .MuiFilledInput-underline:before': {
                                                content: 'none',
                                            },
                                            '& .MuiFilledInput-underline:after': {
                                                content: 'none',
                                            },
                                            '& .MuiFilledInput-root:before': {
                                                content: 'none',
                                            },
                                            '& .MuiFilledInput-root:after': {
                                                content: 'none',
                                            },
                                        }}
                                    />
                                    {contract && isMobile && isActive && (
                                        <div className={cls.bonuses}>
                                            {payForward
                                            && payForward.startTarifPrice !== 0
                                            && Object.entries(payForward.bonusPayout).map(([key, value]) => (
                                                <SetPaymentButton
                                                    number={value}
                                                    key={value}
                                                    unit="баллов"
                                                    description={(key === '6' || key === '12')
                                                        ? (`за ${key} месяцев`)
                                                        : (`за ${key} месяца`)}
                                                    className={classNames(
                                                        cls.paymentButtonBonus,
                                                        { [cls.active]: Number(key) === periodPayment },
                                                    )}
                                                    callback={() => {
                                                        setPeriodPayment(Number(key));
                                                        setSumPayment(+key * contract.price);
                                                    }}
                                                />
                                            ))}
                                        </div>
                                    )}
                                    <div className={cls.wrapperButton}>
                                        <Flex>
                                            <TextField
                                                id="payment"
                                                InputProps={{
                                                    inputProps: { min: 1, max: 30000 },
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                                margin="dense"
                                                label="Сумма, ₽"
                                                variant="filled"
                                                type="number"
                                                sx={{
                                                    flexGrow: 1,
                                                    '& .MuiFilledInput-root': {
                                                        backgroundColor: '#F4F4F4',
                                                        borderRadius: '8px',
                                                        border: '1px solid #49A0B4',
                                                        '&:hover': {
                                                            backgroundColor: '#F4F4F4',
                                                        },
                                                        '&.Mui-focused': {
                                                            backgroundColor: '#F4F4F4',
                                                            border: '1px solid #49A0B4',
                                                        },
                                                    },
                                                    '& .MuiFilledInput-underline:before': {
                                                        content: 'none',
                                                    },
                                                    '& .MuiFilledInput-underline:after': {
                                                        content: 'none',
                                                    },
                                                    '& .MuiFilledInput-root:before': {
                                                        content: 'none',
                                                    },
                                                    '& .MuiFilledInput-root:after': {
                                                        content: 'none',
                                                    },
                                                }}
                                                onInput={(e) => {
                                                    const input = e.target as HTMLInputElement;
                                                    if (input.value) {
                                                        let value = parseInt(input.value, 10);
                                                        if (value > 30000) {
                                                            value = 30000;
                                                        } else if (value < 1) {
                                                            value = 1;
                                                        }
                                                        input.value = value.toString();
                                                    }
                                                }}
                                                {...register('payment')}
                                            />
                                        </Flex>                                
                                        <Button
                                            className={cls.paymentButton}
                                            fullwidth
                                            type="submit"
                                        >
                                            Пополнить
                                        </Button>
                                    </div>
                                    {payForward && payForward.startTarifPrice !== 0 && months > 0 && (
                                        <div className={cls.payForwardLeft}>
                                            <Flex direction="column">
                                                <div className={cls.description}>
                                                    {
                                                        `Активирована акция "Плачу вперед".
                                                        Вам начислятся ${balls} баллов за оплату
                                                        услуг за следующие ${months} ${(
                                            months === 3 || months === 24
                                        ) ? 'месяца' : 'месяцев'}.`
                                                    }
                                                    
                                                </div>
                                            </Flex>
                                        </div>                                        
                                    )}
                                </div>                                
                            </div>
                            {!isMobile && (
                                payForward && payForward.startTarifPrice !== 0 ? (
                                    <div className={cls.payForwardWrapper}>
                                        <Flex direction="column">
                                            <div className={cls.title}>
                                                Акция &quot;Плачу вперед&quot;
                                            </div>
                                            <div className={cls.description}>
                                                Экономьте на связи и зарабатывайте до 1700 бонусных баллов.
                                                Просто оплачивайте услуги на несколько месяцев вперед, и баллы
                                                зачислятся автоматически!
                                            </div>
                                        </Flex>
                                        {contract && (
                                            <div className={cls.bonuses}>
                                                {Object.entries(payForward.bonusPayout).map(([key, value]) => (
                                                    <SetPaymentButton
                                                        number={value}
                                                        key={value}
                                                        unit="баллов"
                                                        description={(key === '6' || key === '12')
                                                            ? (`за ${key} месяцев`)
                                                            : (`за ${key} месяца`)}
                                                        className={classNames(
                                                            cls.paymentButtonBonus,
                                                            { [cls.active]: Number(key) === periodPayment },
                                                        )}
                                                        callback={() => {
                                                            setPeriodPayment(Number(key));
                                                            setSumPayment(Number(key) * contract.price);
                                                        }}
                                                    />
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className={cls.payForwardWrapper}>
                                        <Flex direction="column">
                                            <div className={cls.title}>
                                                Акция &quot;Пригласи друга&quot;
                                            </div>
                                            <div className={cls.description}>
                                                Приглашайте друзей и получайте бонусы за каждого подключившегося.
                                                Делитесь реферальной ссылкой, все прошедшие по ней будут
                                                автоматически приносить бонусы.
                                            </div>
                                        </Flex>
                                        <div className={cls.bonuslink}>
                                            <TextField
                                                id="input-with-icon-textfield"
                                                label="Персональная ссылка"
                                                variant="filled"
                                                value={refLink}
                                                sx={{
                                                    '& .MuiFilledInput-root': {
                                                        backgroundColor: '#F4F4F4',
                                                        borderRadius: '8px',
                                                        border: '1px solid #49A0B4',
                                                        '&:hover': {
                                                            backgroundColor: '#F4F4F4',
                                                        },
                                                        '&.Mui-focused': {
                                                            backgroundColor: '#F4F4F4',
                                                            border: '1px solid #49A0B4',
                                                        },
                                                    },
                                                    '& .MuiFilledInput-underline:before': {
                                                        content: 'none',
                                                    },
                                                    '& .MuiFilledInput-underline:after': {
                                                        content: 'none',
                                                    },
                                                    '& .MuiFilledInput-root:before': {
                                                        content: 'none',
                                                    },
                                                    '& .MuiFilledInput-root:after': {
                                                        content: 'none',
                                                    },
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment
                                                            position="end"
                                                            className={cls.friendLink}
                                                        >
                                                            <CopyClipboard
                                                                className={cls.copySvg}
                                                                onClick={() => {
                                                                    copyToClipboard(refLink);
                                                                }}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />

                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    )}
                </div>
            </Card>
        </form>
    );
};
