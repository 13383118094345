import React from 'react';
import { Button, Typography } from 'shared/ui';
import { YandexShare } from 'widgets/YandexShare';
import useDeviceDetect from 'shared/hooks/useDeviceDetect/useDeviceDetect';
import { XClose } from 'shared/assets/icons';
import cls from './ModalTopTitle.module.scss';

interface ModalTopTitleProps {
  title: string;
  camlink: string;
  cameraName:string;
  neDvr?:boolean;
  onClose: () => void;
}
const ModalTopTitle: React.FC<ModalTopTitleProps> = ({
    title, onClose, camlink, neDvr = true, cameraName,
}) => {
    const { isMobile } = useDeviceDetect();

    return (
        <div className={isMobile ? cls.mobileWrapper : cls.wrapper}>
            <div className={cls.modalTopTitle}>
                <p>
                    {title}
                </p>
                <div className={cls.titleLink}>
                    <Typography variant="body" />
                    {neDvr && <YandexShare cam={camlink} cameraName={cameraName} />}
                </div>
            </div>
            {!isMobile && (
                <div className={cls.buttonClose}>
                    <Button
                        className={cls.buttonCloseButton}
                        variant="noShadow"
                        onClick={onClose}
                    >
                        <XClose />
                    </Button>
                </div>
            )}
        </div>
    );
};
export default ModalTopTitle;
