import React from 'react';
import cls from './Flex.module.scss';

interface FlexProps extends React.ComponentProps<'div'> {
  className?: string;
  justify?: string;
  align?: string;
  gap?: number | string;
  direction?: 'column' | 'row';
  wrap?: 'wrap' | 'nowrap'
}

const Flex: React.FC<FlexProps> = ({
    className,
    justify = 'stretch',
    align = 'stretch',
    gap,
    wrap = 'nowrap',
    direction = 'row',
    ...props
}) => {
    const rootClasses = [cls.Flex, className].filter(Boolean).join(' ');
    return (
        <div
            className={rootClasses}
            style={{
                flexWrap: wrap,
                justifyContent: justify,
                alignItems: align,
                gap: typeof gap === 'number' ? `${gap}px` : gap,
                flexDirection: direction,
            }}
            {...props}
        />
    );
};

export default Flex;
