import React, { useEffect, useState } from 'react';
import {
    Button,
    Flex,
    Input,
    Typography,
} from 'shared/ui';
import { PhoneInput } from 'shared/ui/PhoneInput';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PublicService } from 'shared/api';
import { AddressSearch } from 'shared/ui/SearchAddressInput';
import { useSelector } from 'react-redux';
import { ServiceFormValues } from '../model/types/ServiceFormValues';
import { ServiceFormSchema } from '../model/schema/ServiceFormSchema';
import { profileSelector } from '../../../entities/user/model';
import cls from './AddServiceForm.module.scss';

interface AddServiceFormProps {
    className?: string;
    comment?: string;
    ktv?: boolean;
    inet?: boolean;
}

export const AddServiceForm: React.FC<AddServiceFormProps> = ({
    className, comment, ktv, inet,
    ...props
}) => {
    const profile = useSelector(profileSelector);
    const {
        register, handleSubmit, setValue, watch, formState: { errors },
    } = useForm<ServiceFormValues>({
        mode: 'onBlur',
        resolver: yupResolver(ServiceFormSchema),
        defaultValues: {
            name: profile?.firstName,
        },
    });

    // const [address, setAddress] = useState<AddressSearch>();
    const [address, setAddress] = useState<AddressSearch | null>(null);
    const [ticket, setTicket] = useState<string>('');

    // Watch the address field for changes
    const watchedAddress = watch('address');

    useEffect(() => {
        if (address) {
            setValue('address', address.addressString || '');
        }
    }, [address, setValue]);

    const onSendForm = async (data: ServiceFormValues) => {
        try {
            const res = await PublicService.createConnectionRequest({
                phone: data.phone,
                name: data.name,
                address: data.address,
                street: address?.street,
                home: address?.home,
                city: address?.city,
                comment,
                from: 6,
                ktv,
                inet,
            });
            setTicket(res.data.data.ticketId);
        } catch (e) {
            console.log(e);
        }
    };

    console.log('errors', errors);

    return (
        <div className={cls.wrapper}>
            {ticket ? (
                <div className={cls.successBlock}>
                    <Typography variant="h3" className={cls.heading}>
                        Заявка №
                        {' '}
                        {ticket}
                    </Typography>
                    <Typography variant="body">
                        Наш сотрудник свяжется с вами в ближайшее время для уточнения
                        технической возможности и условий подключения выбранного тарифного
                        плана.
                    </Typography>
                </div>
            ) : (
                <div className={cls.pd}>
                    <Typography variant="h3" className={cls.heading}>
                        Заявка на подключение услуги
                    </Typography>
                    <form className={cls.form} onSubmit={handleSubmit(onSendForm)}>
                        <div>
                            <Input
                                fullwidth
                                placeholder="Имя"
                                id="name"
                                {...register('name')}
                            />
                            {errors.name && <p className={cls.error}>{errors.name.message}</p>}
                        </div>
                        <div>
                            <PhoneInput
                                id="phone"
                                {...register('phone')}
                                fullwidth
                                placeholder="Номер телефона"
                                defaultValue={profile?.phone || ''}
                            />
                            {errors.phone && <p className={cls.error}>{errors.phone.message}</p>}
                        </div>
                        {/* <div>
                            <SearchAddressInput
                                setValue={(value: string) => setValue('address', value)}
                                setOption={setAddress}
                            />
                            {errors.address && !address && <p className={cls.error}>{errors.address.message}</p>}
                        </div> */}
                        <Flex direction="row" justify="center">
                            <Button className={cls.sendButton} type="submit">Отправить</Button>
                        </Flex>
                        <small className={cls.detailsText}>
                            Отправляя заявку, вы соглашаетесь на
                            {' '}
                            <a href="https://baza.net/agreement">обработку персональных данных</a>
                        </small>
                    </form>
                </div>
            )}
        </div>
    );
};
