import { createAsyncThunk } from '@reduxjs/toolkit';
import AuthService from 'shared/api/baza/auth/auth.service';

export const ipAuth = createAsyncThunk(
    'auth/ip_auth',
    async (arg, { rejectWithValue }) => {
        try {
            const response: any = await AuthService.ipAuth();

            return { ...response };
        } catch (e: any) {
            throw new Error(e);
        }
    },
);
