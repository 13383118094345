import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const copyToClipboard = (text:string) => {
    navigator.clipboard.writeText(text);
    toast.success('Скопировано', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
    });
};
