import { createAsyncThunk } from '@reduxjs/toolkit';
import VerificationService from 'shared/api/baza/verification.service';

export const getCodeByPhone: any = createAsyncThunk(
    'Verification/getCodeByPhone',
    async (args: { phone: string }, { rejectWithValue, dispatch }) => {
        try {
            const { phone } = args;
            const response = await VerificationService.send(phone);

            return { phone, message: response.data.data.result };
        } catch (e: any) {
            throw new Error(e);
        }
    },
);
