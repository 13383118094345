import React, {
    FC, forwardRef, useState, useEffect, useCallback,
} from 'react';
import cls from './Textarea.module.scss';

interface TextareaProps extends React.ComponentPropsWithRef<'textarea'> {
  className?: string;
  maxLength?: number;
}

export const Textarea: FC<TextareaProps> = forwardRef(({
    className, maxLength, value, onChange, ...props
}: TextareaProps, ref) => {
    const [text, setText] = useState(value ? value.toString() : '');

    useEffect(() => {
        if (value !== undefined) {
            setText(value.toString());
        }
    }, [value]);

    const handleChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = event.target.value;
        if (maxLength === undefined || newValue.length <= maxLength) {
            setText(newValue);
            if (onChange) {
                onChange(event);
            }
        }
    }, [maxLength, onChange]);

    const rootClasses = [cls.Textarea, className].filter(Boolean).join(' ');

    return (
        <div className={cls.TextareaContainer}>
            <textarea
                className={rootClasses}
                ref={ref}
                maxLength={maxLength}
                value={text}
                onChange={handleChange}
                {...props}
            />
            {maxLength && (
                <div className={text.length === 200 ? cls.charCnt : cls.charCount}>
                    {maxLength - text.length}
                    {' '}
                    /
                    {' '}
                    {maxLength}
                </div>
            )}
        </div>
    );
});
