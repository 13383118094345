import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider/config/StateSchema';
import { IAdditionalAddress } from '../../types/IAdditionalAddress';

interface AddAddressArgs extends Pick<IAdditionalAddress, 'addrId' | 'oper' | 'flat'> {
    address: string;
}
export const addAdditionalAddress = createAsyncThunk<IAdditionalAddress, AddAddressArgs, ThunkConfig<string>>(
    'additionalAddress/addAdditionalAddress',
    async (addAddressProps, { extra, rejectWithValue }) => {
        try {
            const response = await extra.api.post<{data: IAdditionalAddress}>('/user/address', addAddressProps);

            return response.data.data;
        } catch (e) {
            return rejectWithValue('error');
        }
    },
);
