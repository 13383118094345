import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider/config/StateSchema';
import { $api } from 'shared/api';
import { userActions } from 'entities/user/model';
import { IAdditionalAddress } from '../../types/IAdditionalAddress';

export const fetchAdditionalAddresses = createAsyncThunk<
  IAdditionalAddress[],
  void,
  ThunkConfig<string>
>(
    'additionalAddress/fetchAdditionalAddresses',
    async (_, {
        extra, rejectWithValue, dispatch, getState,
    }) => {
        try {
            const response = await $api.get<{ data: IAdditionalAddress[] }>(
                '/user/address',
            );

            dispatch(userActions.setAddresses(response.data.data));

            return response.data.data;
        } catch (e) {
            return rejectWithValue('error');
        }
    },
);
