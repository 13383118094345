import * as yup from 'yup';

export const ServiceFormSchema = yup.object({
    name: yup.string().required('Имя обязательно'),
    address: yup.string(),
    phone: yup
        .string()
        .required('Поле обязательно к заполнению!')
        .min(16, 'Номером телефона должен быть корректным!'),
});
