import React from 'react';
import {
    Button, Card, Flex, Typography,
} from 'shared/ui';
import cls from './PageError.module.scss';

interface PageErrorProps {
  className?: string;
}

export const PageError: React.FC<PageErrorProps> = ({ className, ...props }) => {
    const rootClasses = [cls.PageError, className].filter(Boolean).join(' ');

    const onReload = () => {
        window.location.reload();
    };

    return (
        <div className={rootClasses} {...props}>
            <Card className={cls.messageCard}>
                <Flex direction="column" gap={30} justify="center">
                    <div>
                        <Typography variant="h2">Произошла непредвиденная ошибка</Typography>
                        <Typography variant="body">
                            Попробуйте перезагрузить страницу, если это не поможет, сообщите нам.
                        </Typography>
                    </div>
                    <Button onClick={onReload} variant="secondary">Перезагрузить</Button>
                </Flex>
            </Card>
        </div>
    );
};
