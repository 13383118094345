import { createSlice } from '@reduxjs/toolkit';
import { VariablesInitialState } from '../types/VariablesState';
import { fetchVariables } from '../services/fetchVariables';

const initialState: VariablesInitialState = {
    title: '',
    status: 'idle',
    error: null,
};

const variablesSlice = createSlice({
    name: 'variables',
    initialState,
    reducers: {
        setVariables: (state, action) => {
            state.variables = action.payload.variables;
        },
        removeVariables: (state: VariablesInitialState) => {
            state.variables = undefined;
        },
        setTitle: (state, action) => {
            state.title = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchVariables.pending, (state) => {
                state.status = 'loading';
            })
        // TODO: change string to  boolean
            .addCase(fetchVariables.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.variables = action.payload;
            });
    },
});

export const { actions: variablesActions, reducer: variablesReducer } = variablesSlice;
