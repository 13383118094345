export interface IAdditionalAddress {
  id: number;
  oper: string;
  addrId: number;
  flat: string;
  city: string;
  street: string;
  home: string;
  dvr: boolean;
  domofon: boolean;
  verificationStatus: 'NOT_VERIFIED' | 'VERIFIED' | 'WAITING' | 'DENY';
}

export type VerifyStatusType = 'NOT_VERIFIED' | 'VERIFIED' | 'WAITING' | 'DENY';
export const verifyStatuses = {
    NOT_VERIFIED: 'Не верифицирован',
    VERIFIED: 'Верифицирован',
    WAITING: 'Ожидание',
    DENY: 'Отказ',
};
