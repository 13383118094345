import { createSelector } from '@reduxjs/toolkit';
import { variablesSelector } from '../variablesSelector/variablesSelector';

export const supportNumberSelector = createSelector(
    variablesSelector,
    (variables) => variables?.variables?.contacts?.support?.phone.visible,
);
export const supportDataSelector = createSelector(
    variablesSelector,
    (variables) => variables?.variables?.contacts?.support,
);
