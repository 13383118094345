import {
    configureStore,
    ReducersMapObject,
} from '@reduxjs/toolkit';
import { authReducer } from 'features/Auth/model';
import { userReducer } from 'entities/user/model';
import { variablesReducer } from 'entities/variables';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { verificationReducer } from 'features/Verification';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { $api } from 'shared/api';
import { additionalAddressReducer } from 'entities/additionalAddress';
import * as process from 'process';
import { faqReducer } from 'pages/FaqPage';
import { mapReducer } from 'widgets/Map/Model/slice/mapSlice';
import { paymentFormReducer } from 'features/PaymentForm';
import { locationReducer } from 'entities/location/model/slice/locationSlice';
import { internetTariffReducer } from 'entities/InteretTariff/model/slice/internetTariffSlice';
import { internetTariffCardReducer } from 'pages/InternetPage/model/slice/internetTariffCardSlice';
import { accidentsReducer } from 'widgets/AccidentsList';
import { mapPageReducer } from 'pages/MapPage';
import { StateSchema, ThunkExtraArg } from './StateSchema';

export function createReduxStore(initialState?: StateSchema) {
    const rootReducer: ReducersMapObject<StateSchema> = {
        auth: authReducer,
        user: userReducer,
        variables: variablesReducer,
        toastr: toastrReducer,
        verification: verificationReducer,
        faq: faqReducer,
        mapPage: mapPageReducer,
        accidents: accidentsReducer,
        additionalAddress: additionalAddressReducer,
        map: mapReducer,
        paymentForm: paymentFormReducer,
        location: locationReducer,
        internetTariff: internetTariffReducer,
        internetTariffCard: internetTariffCardReducer,
    };
    const extraArg: ThunkExtraArg = {
        api: $api,
    };

    // @ts-ignore
    return configureStore({
        reducer: rootReducer,
        preloadedState: initialState,
        devTools: process.env.NODE_ENV === 'development',
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            thunk: {
                extraArgument: extraArg,
            },
        }),
    });
}

export type AppDispatch = ReturnType<typeof createReduxStore>['dispatch'];
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<StateSchema> = useSelector;
