import React from 'react';
import { IContract } from 'entities/user/model/types';
import { Flex } from 'shared/ui';
import cls from './AdditionalServicesBlock.module.scss';

interface PropsBlock {
    content: string;
    isActive?: boolean;
    onClickModal?: (str: string) => void;
}
const BlockService = (props: PropsBlock) => {
    const {
        content,
        isActive = false,
        onClickModal,
    } = props;

    const isClick = !isActive && onClickModal;

    return (
        <div
            className={isActive ? cls.addedOnAddress : cls.onAddress}
            onClick={isClick ? () => onClickModal(content) : () => {
            }}
        >
            <span>{content}</span>
        </div>
    );
};

interface AdditionalProps {
    contract: IContract;
    onClickModal: (str: string) => void;
}
export const AdditionalServicesBlock = (props: AdditionalProps) => {
    const {
        contract,
        onClickModal,
    } = props;

    const hasService = (contract: any, type: any): boolean => contract.set.some((item: any) => item.type === type);

    return (
        <div className={cls.additionalServices}>
            <Flex direction="row" gap={10}>
                <div className={cls.servicesList}>
                    {hasService(contract, 'inet')
                        && <BlockService isActive content="Интернет" />}

                    {hasService(contract, 'ktv')
                        && <BlockService isActive content="Кабельное ТВ" />}

                    {hasService(contract, 'mobile')
                        && <BlockService isActive content="Мобильная связь" />}

                    {!hasService(contract, 'inet')
                        && contract.address.inet
                        && <BlockService onClickModal={onClickModal} content="+ Интернет" />}

                    {!hasService(contract, 'ktv')
                        && contract.address.ktv
                        && <BlockService onClickModal={onClickModal} content="+ Кабельное ТВ" />}
                    {contract.address.ktv
                        && <BlockService onClickModal={onClickModal} content="+ Интерактивное ТВ" />}

                    {!hasService(contract, 'mobile')
                        && <BlockService onClickModal={onClickModal} content="+ Мобильная связь" />}
                </div>
            </Flex>
        </div>
    );
};
