import React from 'react';
import cls from './Card.module.scss';

interface CardProps extends React.ComponentProps<'div'> {
    className?: string;
    gradient?: boolean;
    radial?:boolean;
}

const Card: React.FC<CardProps> = ({
    className, gradient, radial, ...props
}) => {
    const rootClasses = [cls.Card, gradient && cls.gradient, radial && cls.radial, className]
        .filter(Boolean)
        .join(' ');
    return <div className={rootClasses} {...props} />;
};

export default Card;
