import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchAccidents } from 'widgets/AccidentsList/model/services/fetchAccidents/fetchAccidents';
import { AccidentsArticle, AccidentsSchema } from '../types/accidentsSchema';

const initialState: AccidentsSchema = {
    data: [],
    isLoading: false,
    error: undefined,
};
const accidentsSlice = createSlice({
    name: 'accidents',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchAccidents.pending, (state) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(fetchAccidents.fulfilled, (state, action: PayloadAction<AccidentsArticle[]>) => {
                state.data = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchAccidents.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export const { actions: accidentsActions, reducer: accidentsReducer } = accidentsSlice;
