import { NavLink } from 'react-router-dom';
import { classNames } from 'shared/lib';
import cls from './NavLinksDropMenu.module.scss';

type item = {
    text: string;
    link: string;
    icon?: null;
}

interface DropProps {
    menuItem: item[],
}

export const NavLinksDropMenu = (props: DropProps) => {
    const { menuItem } = props;

    const strCls = (isActive: boolean) => classNames(cls.link, {
        [cls.active]: isActive,
    });

    return (
        <>
            {
                menuItem.map((item) => (
                    <NavLink
                        key={item.link}
                        to={item.link}
                        className={({ isActive }) => strCls(isActive)}
                    >
                        {item.text}
                    </NavLink>
                ))
            }
        </>
    );
};
