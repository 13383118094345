import React from 'react';
import cls from './Input.module.scss';
import { classNames } from '../../lib';

export interface InputProps extends React.ComponentPropsWithRef<'input'> {
    variant?: 'outlined' | 'contained' | 'standart';
    fullwidth?: boolean;
    textCenter?: boolean;
    big?: boolean;
    icon?: 'ruble';
    label?: string;
    outlineNone?: boolean;
    error?: boolean;
    autocomplete?: boolean;
    options?: Array<string>;
}

const Input: React.FC<InputProps> = React.forwardRef(
    (
        {
            variant = 'outlined',
            big,
            icon,
            label,
            fullwidth,
            error,
            autocomplete,
            textCenter,
            outlineNone,
            options,
            ...props
        }: InputProps,
        ref,
    ) => (
        <span
            className={classNames(cls.InputContainer, {}, [icon ? cls[icon] : ''])}
        >
            {label && <span className={cls.label}>{label}</span>}
            <input
                className={classNames(
                    cls.Input,
                    {
                        [cls.fullwidth]: !!fullwidth,
                        [cls.big]: !!big,
                        [cls.error]: !!error,
                        [cls.textCenter]: !!textCenter,
                        [cls.outlineNone]: !!outlineNone,
                    },
                    [cls[variant]],
                )}
                ref={ref}
                {...props}
            />
            {autocomplete && (
                <div className={cls.autocomplete}>
                    <ul>
                        {options?.map((option) => (
                            <li key={option}>{option}</li>
                        ))}
                    </ul>
                </div>
            )}
        </span>
    ),
);

export default Input;
