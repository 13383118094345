import { IContract } from '../../types';

export function createContractView(contract: IContract) {
    const payDateLocale = new Date(contract.payDate).toLocaleDateString('ru-RU', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });

    const tariffString = (() => {
        let string = '';
        contract.set.forEach((item) => {
            if (item.type === 'inet') {
                string = `${item.data.tarif.name}`;
            }

            if (item.type === 'ktv' && string) {
                string = `${item.data.tarif.name} + ТВ`;
            }

            if (item.type === 'ktv' && !string) {
                string = 'Кабельное ТВ';
            }
        });
        return string;
    })();

    const addressFull = `г.${contract.address.city}, ${
        contract.address.street
    } ${contract.address.home}, кв.${contract.address.flat}`;

    return {
        ...contract,
        payDateLocale,
        tariffString,
        addressFull,
    };
}
