import { createSlice } from '@reduxjs/toolkit';
import { VerificationSchema } from '../types/VerificationSchema';
import { getCodeByPhone } from '../service/getCodeByPhone/getCodeByPhone';
import { verifyPhone } from '../service/verifyPhone/verifyPhone';

const initialState: VerificationSchema = {
    phone: undefined,
    code: null,
    step: 'phone',
    status: 'idle',
    error: null,
};
export const verificationSlice = createSlice({
    name: 'verification',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getCodeByPhone.pending, (state, action) => {})
            .addCase(getCodeByPhone.fulfilled, (state, action) => {
                state.phone = action.payload.phone;
                if (action.payload.message === 'Phone number already verified') {
                    state.step = 'success';
                } else {
                    state.step = 'code';
                }
                state.status = 'succeeded';
            })
            .addCase(getCodeByPhone.rejected, (state, action) => {
                state.error = action.error;
                state.status = 'failed';
                state.step = 'phone';
            })
            .addCase(verifyPhone.pending, (state, action) => {})
            .addCase(verifyPhone.fulfilled, (state, action) => {
                state.step = 'success';
            })
            .addCase(verifyPhone.rejected, (state, action) => {
                state.error = 'wrong code';
                state.code = action.meta.arg.code;
            });
    },
});

export const { actions: verificationActions, reducer: verificationReducer } = verificationSlice;
