import React, {
    FC, useCallback, useEffect, useState, 
} from 'react';
import { AdaptiveModal } from 'shared/ui/AdaptiveModal/AdaptiveModal';
import { Button, Flex } from 'shared/ui';
import { NewPlus, XClose } from 'shared/assets/icons';
import { TextField } from '@mui/material';
import debounce from 'lodash/debounce';
import { PublicService, UserService } from 'shared/api';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { currentContractSelector, profileSelector, userActions } from 'entities/user/model';
import { toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import { additionalUidSelector } from 'entities/user/model/selectors/additionalUidSelector/additionalUidSelector';
import cls from './AddAdditionalUidForm.module.scss';

interface AddressResponse {
    address: string;
    oper: string;
    operTitle: string;
}

interface AddAdditionalUidFormProps {
    parentOper?: string;
}

interface FormValues {
    contractNumber: string;
    email: string;
}

export const AddAdditionalUidForm: FC<AddAdditionalUidFormProps> = () => {
    const [openWindow, setOpenWindow] = useState(false);
    const [address, setAddress] = useState<string | null>(null);
    const [oper, setOper] = useState<string | null>(null);
    const [isLoadingBtn, setIsLoadingBtn] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const currentContract = useSelector(currentContractSelector);
    const additionalUidList = useSelector(additionalUidSelector);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profile = useSelector(profileSelector);
    const {
        control, handleSubmit, setValue, watch, formState: { errors }, 
    } = useForm<FormValues>({
        defaultValues: {
            contractNumber: '',
            email: profile?.email || '',
        },
    });

    const contractNumber = watch('contractNumber');

    const debouncedSearch = useCallback(
        debounce(async (number: string) => {
            if (number) {
                try {
                    const response = await PublicService.searchAddressByUid(Number(number));
                    const responseData = response.data.data[0] as AddressResponse;
                    if (responseData) {
                        setAddress(responseData.address);
                        setOper(responseData.oper);
                    } else {
                        setAddress(null);
                        setOper(null);
                    }
                } catch (error) {
                    console.error('Ошибка при поиске адреса', error);
                    setAddress(null);
                    setOper(null);
                }
            } else {
                setAddress(null);
                setOper(null);
            }
        }, 500),
        [],
    );

    useEffect(() => {
        debouncedSearch(contractNumber);
        return () => {
            debouncedSearch.cancel();
        };
    }, [contractNumber, debouncedSearch]);

    const onSubmit = async (data: FormValues) => {
        if (!currentContract || !profile?.phone || additionalUidList.find((id) => id.uid === Number(contractNumber))) {
            if (oper && address) {
                const paymentData = {
                    uid: Number(contractNumber),
                    address_full: address,
                    email: data.email,
                    payment: 700,
                    oper,
                };
                localStorage.setItem('paymentData', JSON.stringify(paymentData));
                navigate('/pay');
            }
            return;
        }
        if (oper && address) {
            try {
                setIsLoadingBtn(true);
                const response = await UserService.addAdditionalUid(oper, Number(contractNumber));
                const resDate = response.data.data.parentUid;
                const paymentData = {
                    uid: resDate,
                    address_full: address,
                    email: data.email || profile?.email,
                    payment: 500,
                    oper,
                };
                const payData = {
                    uid: resDate,
                    phone: '',
                    oper,
                    defaultPrice: 500,
                };
                localStorage.setItem('paymentData', JSON.stringify(paymentData));
                // @ts-ignore
                dispatch(userActions.setAddedUid(payData));
                setOpenWindow(false);
                toast.success('Адрес успешно добавлен!');
                setTimeout(() => {
                    navigate('/pay');
                }, 1000);
            } catch (error) {
                toast.error('Ошибка при добавлении адреса');
            } finally {
                setIsLoadingBtn(false);
            }
        } else {
            setIsSubmitted(true);
        }
    };

    return (
        <div className={cls.addUidCard}>
            <div
                className={cls.dopContractCard}
                onClick={() => setOpenWindow(true)}
            >
                <div className={cls.plusSvg}>
                    <NewPlus />
                </div>
                <p>
                    Вы можете оплатить любой лицевой счёт, не привязанный к этому Личному кабинету
                </p>
            </div>
            <AdaptiveModal
                initialFocusRef={false}
                open={openWindow}
                onClose={() => setOpenWindow(false)}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={cls.wrapper}>
                        <div className={cls.titleWrapper}>
                            <div className={cls.title}>Добавление договора для оплаты</div>
                            <Button
                                variant="noShadow"
                                onClick={() => setOpenWindow(false)}
                                className={cls.closeButton}
                            >
                                <XClose />
                            </Button>
                        </div>
                        <p>
                            Вы можете произвести оплату за своих близких,
                            для этого Вам необходимо знать номер существующего лицевого счета.
                        </p>
                        <Controller
                            name="contractNumber"
                            control={control}
                            rules={{
                                required: 'Введите номер договора',
                                pattern: {
                                    value: /^\d{0,6}$/,
                                    message: 'Номер договора должен содержать до 6 цифр',
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    className={cls.textField}
                                    placeholder="Номер договора"
                                    error={!!errors.contractNumber}
                                    helperText={errors.contractNumber?.message}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '8px',
                                            '&:hover fieldset': {
                                                borderColor: '#49A0B4',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#49A0B4',
                                            },
                                        },
                                    }}
                                />
                            )}
                        />
                        <div className={cls.addressPlaceholder}>
                            {`Проверьте адрес: ${address || ''}`}
                            <p>Чек будет отправлен на почту:</p>
                        </div>
                        <Controller
                            name="email"
                            control={control}
                            rules={{
                                required: 'Введите email',
                                pattern: {
                                    value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                    message: 'Некорректный email',
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    className={cls.textField}
                                    placeholder="Email"
                                    error={!!errors.email && isSubmitted}
                                    helperText={isSubmitted && errors.email ? errors.email.message : ''}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '8px',
                                            '&:hover fieldset': {
                                                borderColor: '#49A0B4',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#49A0B4',
                                            },
                                        },
                                    }}
                                />
                            )}
                        />
                    </div>
                    <Flex align="center" justify="center">
                        <Button
                            className={cls.findButton}
                            disabled={isLoadingBtn || !address || !contractNumber}
                            onClick={() => setIsSubmitted(true)}
                            type="submit"
                        >
                            {isLoadingBtn ? 'Загрузка...' : 'Добавить'}
                        </Button>
                    </Flex>
                </form>
            </AdaptiveModal>
        </div>
    );
};
