import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider/config/StateSchema';
import { toast } from 'react-toastify';

interface deleteAdditionalAddressResponse {
    result: boolean;
}
export const deleteAdditionalAddress = createAsyncThunk<
    number,
    number,
    ThunkConfig<string>
>('additionalAddress/deleteAdditionalAddress', async (
    addressId,
    { extra, dispatch, rejectWithValue },
) => {
    try {
        const response = await extra.api.delete<{
            data: deleteAdditionalAddressResponse
        }>(`/user/address/${addressId}`);
        toast.success('Адрес успешно удален');
        if (!response.data.data.result) {
            toast.error('Ошибка удаления');
            return rejectWithValue('error');
        }
        return addressId;
    } catch (e) {
        toast.error('Ошибка удаления');
        return rejectWithValue('error');
    }
});
