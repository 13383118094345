import { createAsyncThunk } from '@reduxjs/toolkit';
import UserService from 'shared/api/baza/user.service';
import { IUser } from '../../types/IUser';

export const fetchUserInfo = createAsyncThunk<IUser>(
    'user/fetchUserInfo',
    async (_, thunkAPI) => {
        try {
            const response = await UserService.getInfo();
            return { ...response?.data.data };
        } catch (e: any) {
            throw new Error(e.message);
        }
    },
);
