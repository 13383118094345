import React, { HTMLAttributes, memo, ReactNode } from 'react';
import cls from './Avatar.module.scss';

interface AvatarProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

export const Avatar = memo(({ children, ...props }: AvatarProps) => {
    const rootClasses = [cls.Avatar].filter(Boolean).join(' ');
    return (
        <div className={rootClasses} {...props}>
            {children}
        </div>
    );
});
