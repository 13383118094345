import { createAsyncThunk } from '@reduxjs/toolkit';
import { IAdditionalAddress } from 'entities/additionalAddress/model/types/IAdditionalAddress';
import { ThunkConfig } from 'app/providers/StoreProvider/config/StateSchema';

interface VerificationProps {
    addressId: number;
    formData: FormData;
}

export const verificationAddressByPhoto = createAsyncThunk<IAdditionalAddress, VerificationProps, ThunkConfig<string>>(
    'additionalAddress/verificationAddressByPhoto',
    async ({ addressId, formData }, { extra, rejectWithValue, dispatch }) => {
        try {
            const response = await extra.api.post<{data: IAdditionalAddress}>(
                `/verification/address/${addressId}`,
                formData,
            );

            return response.data.data;
        } catch (e) {
            return rejectWithValue('error');
        }
    },
);
