import { createSlice } from '@reduxjs/toolkit';
import { InternetTariffState } from '../types/InternetTariffState';
import { fetchInternetTariff } from '../services/fetchInternetTariff';
import { ITariff } from '../types/ITariff';

const initialState: InternetTariffState = {
    isLoading: false,
    data: [] as ITariff[],
};

export const internetTariffSlice = createSlice({
    name: 'internetTariff',
    initialState,
    reducers: {
        setState: (state, action) => {
            state.data = action.payload.data;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchInternetTariff.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchInternetTariff.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload.data;
            })
            .addCase(fetchInternetTariff.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

export const { actions: internetTariffActions, reducer: internetTariffReducer } = internetTariffSlice;
