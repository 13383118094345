import { createAsyncThunk } from '@reduxjs/toolkit';
import PublicService from 'shared/api/baza/public/public.service';
import { IVariables } from '../types/IVariables';

export const fetchVariables = createAsyncThunk<IVariables>(
    'variables/fetchVariables',
    async () => {
        try {
            const response = await PublicService.getInfo();
            return { ...response?.data.data };
        } catch (e: any) {
            return e.message;
        }
    },
);
