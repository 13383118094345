import React, { useEffect } from 'react';
import { Button, Typography } from 'shared/ui';
import { ProfileArrow } from 'shared/assets/icons';
import { useSelector } from 'react-redux';
import {
    accidentsLoadingStatusSelector,
    accidentsSelector,
} from 'entities/variables/model';
import { useAppDispatch } from 'app/providers/StoreProvider';
import { Loader } from 'shared/ui/Loader';
import { Container } from '@mui/material';
import PlayIcon from 'shared/assets/icons/play-solid.svg';
import { fetchAccidents } from '../model/services/fetchAccidents/fetchAccidents';
import cls from './AccidentsList.module.scss';

interface AccidentsPageProps {
    setShowAccidents: (value: boolean) => void;
    albumId: number;
}

const AccidentsList: React.FC<AccidentsPageProps> = ({ setShowAccidents, albumId }) => {
    const accidentsData = useSelector(accidentsSelector);
    const isLoading = useSelector(accidentsLoadingStatusSelector);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchAccidents(albumId));
    }, []);

    // имитация пагинации
    let data = accidentsData;
    if (accidentsData && accidentsData.length > 12) {
        data = accidentsData.slice(0, 12);
    }

    if (isLoading) {
        return (
            <Container sx={{ py: 2 }} className={cls.modalMinSize}>
                <Loader />
            </Container>
        );
    }

    return (
        <>
            <div className={cls.arrowTitle}>
                {' '}
                <div
                    className={cls.backButtonField}
                    onClick={() => setShowAccidents(false)}
                >
                    <Button
                        variant="link"                        
                        className={cls.backButton}
                    >
                        <ProfileArrow />
                    </Button>
                </div>
                <Typography
                    variant="h3"
                    align="center"
                    size="size_l"
                    className={cls.titleLine}
                >
                    Аварии
                </Typography>
            </div>

            <div className={cls.accidents}>
                {accidentsData && data.map(
                    (el) => (
                        <div
                            key={el.id}
                            className={cls.blockAcc}
                        >
                            <p>{el.title}</p>
                            <div className={cls.img} onClick={() => window.open(el.video, '_blank')}>
                                <img className={cls.poster} src={el.poster} alt="poster" />
                                <div className={cls.controls}>
                                    <PlayIcon className={cls.play} />
                                </div>
                            </div>
                        </div>
                    ),
                )}
            </div>
        </>

    );
};

export default AccidentsList;
