import React, { useMemo } from 'react';
import { Flex } from 'shared/ui';
import {
    HomeNew, icDomofon, icDvor, icHelp, icMap,
} from 'shared/assets/icons';
import { NavLink, useLocation } from 'react-router-dom';
import cls from './BottomBar.module.scss';

interface BottomBarProps {
  className?: string;
}

export const BottomBar: React.FC<BottomBarProps> = ({
    className,
}) => {
    const location = useLocation();
    const items = useMemo(
        () => [
            {
                label: '',
                link: '/',
                icon: HomeNew,
                titleBar: 'Baza.net',
            },
            {
                label: '',
                link: '/dvr',
                icon: icDvor,
                titleBar: 'Мой двор',
            },
            {
                label: '',
                link: '/map',
                icon: icMap,
                titleBar: 'Карта',
            },
            {
                label: '',
                link: '/intercom',
                icon: icDomofon,
                titleBar: 'Домофон',
            },
            {
                label: '',
                link: '/faq',
                icon: icHelp,
                titleBar: 'Помощь',
            },
        ],
        [],
    );

    const rootClasses = [cls.BottomBar, className].filter(Boolean).join(' ');
    return (
        <div className={rootClasses}>
            <Flex justify="space-around">
                {items.map((item) => (
                    <NavLink
                        className={`${cls.bottomButton} ${location.pathname === item.link ? cls.activeLink : ''}`}
                        key={item.link}
                        to={item.link}
                        style={{
                            color: location.pathname === item.link ? '#C96065' : '#63ABBE',
                        }}
                    >
                        <item.icon
                            className={cls.icon}
                            style={{
                                stroke: location.pathname === item.link ? '#C96065' : '#63ABBE',
                                scale: location.pathname === item.link ? '1.1' : '1',
                            }}
                        />
                        {item.titleBar}
                    </NavLink>
                ))}
            </Flex>
        </div>
    );
};
