import React, { HTMLAttributes } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button } from 'shared/ui';
import { PhoneInput } from 'shared/ui/PhoneInput';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'app/providers/StoreProvider';
import { removePhoneMask } from 'shared/lib';
import { ipAuth } from 'features/Auth/model';
import cls from './LoginForm.module.scss';

const schema = yup.object().shape({
    phone: yup
        .string()
        .required('Обязательное поле для ввода!')
        .min(16, 'Некорректно введен номер!'),
});

type FormValues = {
  phone: string;
};

interface LoginFormProps extends HTMLAttributes<HTMLFormElement> {
    // eslint-disable-next-line no-unused-vars
    delayLogin: (phone: number) => void;
    error: string;
    telAuth:boolean;
    loading: boolean;
}
const LoginForm: React.FC<LoginFormProps> = ({
    delayLogin,
    telAuth,
    error,
    loading,
    ...props
}) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormValues>({
        mode: 'onSubmit',
        resolver: yupResolver(schema),
    });
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const submitHandler = ({ phone }: FormValues) => {
        const userID = +removePhoneMask(phone);
        delayLogin(userID);
    };

    const onWifiAuth = () => {
        dispatch(ipAuth())
            .then(() => {
                localStorage.setItem('isLogin', '1');
                navigate(location.state?.from || '/');
            })
            .catch(() => {
                localStorage.setItem('isLogin', '0');
            });
    };
    return (
        <form onSubmit={handleSubmit(submitHandler)} {...props}>
            <div className={cls.phoneWrap}>
                {telAuth ? (
                    <div className={`${cls.phoneInputWrapper} ${errors.phone ? cls.red : ''}`}>
                        <div className={cls.phoneWrapTitle}>Введите номер телефона</div>
                        <PhoneInput
                            id="phone"
                            {...register('phone')}
                            big
                            fullwidth
                            outlineNone
                            inputMode="tel"
                            variant="standart"
                            placeholder="+7(999)999-99-99"
                        />
                        {telAuth && (
                            <div className={cls.errField}>
                                <div className={cls.errorMessage}>
                                    {errors.phone ? errors.phone.message : ''}
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className={cls.wifiText}>
                        Если Вы являетесь нашим абонентом,
                        возможен вход по Вашей сети Wi-Fi
                    </div>
                )}
            </div>            
            <div className={cls.Buttons}>
                {telAuth && (
                    <Button className={cls.phoneBtn} fullwidth type="submit">
                        {error ? 'Попробовать снова' : 'Войти'}
                    </Button>
                )}
                {!telAuth && (
                    <Button
                        className={cls.wifi}
                        onClick={onWifiAuth}
                        fullwidth
                        variant="primary"
                        type="submit"
                    >
                        Войти по Wi-Fi
                    </Button>

                )}
            </div>
        </form>
    );
};

export default LoginForm;
