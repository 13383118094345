import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StateSchema } from 'app/providers/StoreProvider';
import { fetchUserInfo, changeContractTariff, createContractView } from '../services';
import { UserState, IAdditionalAddress, IContract } from '../types';
import { IAdditionalUid } from '../types/IAdditionalUid';

export const contractsAdapter = createEntityAdapter<IContract>({
    selectId: (contract) => contract.uid,
});

export const contractSelectors = contractsAdapter.getSelectors<StateSchema>(
    (state) => state.user.services,
);

const initialState: UserState = {
    services: contractsAdapter.getInitialState(),
    domofon: {
        sputnik: [],
        ufanet: [],
    },
    dvr: undefined,
    profile: undefined,
    credits: [],
    currentContract: undefined,
    additional_addresses: [],
    additional_uids: [],
    status: 'idle', // idle, loading, succeeded, failed
    error: null,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setContracts: (state, action) => {
            contractsAdapter.setAll(
                state.services,
                action.payload.contracts.map(createContractView),
            );
            state.currentContract = action.payload.currentContract;
            state.profile = action.payload.profile;
        },
        setCurrentContract: (state, action) => {
            if (!action.payload) {
                localStorage.removeItem('choosenAddress');
                state.currentContract = undefined;
                return;
            }
            const addressData = {
                choosenAddress: action.payload.addressFull,
                uid: action.payload.uid,
                oper: action.payload.oper,
            };

            localStorage.setItem('choosenAddress', JSON.stringify(addressData));

            state.currentContract = action.payload;
        },
        resetCurrentContract: (state, action) => {
            if (state.services.ids.length > 0) {
                state.currentContract = state.services.entities[state.services.ids[0]];
            } else {
                state.currentContract = undefined;
            }
        },
        removeContracts: (state) => {
            contractsAdapter.removeAll(state.services);
            state.currentContract = undefined;
            state.profile = undefined;
        },
        clearError: (state) => {
            state.error = null;
        },
        setAddresses: (state, action: PayloadAction<IAdditionalAddress[]>) => {
            state.additional_addresses = action.payload;
        },
        setAddedUid: (state, action: PayloadAction<IAdditionalUid[]>) => {
            if (!state.additional_uids) {
                state.additional_uids = [];
            }
            state.additional_uids = state.additional_uids.concat(action.payload);
        },
        removeAddressById: (state, action: PayloadAction<number>) => {
            state.additional_addresses = state.additional_addresses.filter(
                (address) => address.id !== action.payload,
            );
        },
        removeAddedUidByUid: (state, action: PayloadAction<number>) => {
            state.additional_uids = state.additional_uids.filter(
                (address) => address.uid !== action.payload,
            );
        },
        resetProfile: (state) => {
            state.profile = undefined;
            contractsAdapter.removeAll(state.services);
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchUserInfo.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(fetchUserInfo.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.profile = action.payload.profile;

                if (action.payload.services) {
                    contractsAdapter.setAll(
                        state.services,
                        action.payload.services?.map(createContractView),
                    );
                }

                const firstContract = action.payload.services?.[0];
                if (firstContract) {
                    state.currentContract = createContractView(firstContract);
                } else {
                    state.currentContract = undefined;
                }
                state.credits = action.payload.credits;
                state.domofon = action.payload.domofon;
                state.dvr = action.payload.dvr;
                state.additional_addresses = action.payload.additional_addresses;
                state.additional_uids = action.payload.additional_uids;
            })
            .addCase(fetchUserInfo.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                console.log('erorr user');
            })
            .addCase(changeContractTariff.pending, (state) => {
                state.error = undefined;
                state.status = 'loading';
            })
            .addCase(changeContractTariff.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(changeContractTariff.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export const { actions: userActions, reducer: userReducer } = userSlice;
