import { Button, Typography } from 'shared/ui';
import React, { FC } from 'react';
import { classNames } from 'shared/lib';
import cls from './SetPaymentButton.module.scss';

interface SetPaymentButtonProps {
    number: number;
    unit: string;
    description: string;
    callback: () => void;
    className?: string
}
const SetPaymentButton: FC<SetPaymentButtonProps> = ({
    callback, unit, number, description, className,
}) => (
    <Button
        className={classNames(cls.SetPaymentButton, {}, [className])}
        onClick={callback}
        type="button"
        fullwidth
        variant="clear"
    >
        <Typography className={cls.numbers} align="center" variant="body">{`+${number} ${unit}`}</Typography>
        <Typography className={cls.desc} align="center" variant="bold">{description}</Typography>
    </Button>
);

export default SetPaymentButton;
