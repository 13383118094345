import { createSlice } from '@reduxjs/toolkit';
import { fetchUserInfo } from 'entities/user/model/services';
import { searchAddressByUid } from '../services/searchAddressByUid';
import { PaymentFormSchema } from '../types/PaymentFormSchema';

const initialState: PaymentFormSchema = {};
const paymentFormSlice = createSlice({
    name: 'paymentsForm',
    initialState,
    reducers: {
        setUid: (state, action) => {
            state.uid = action.payload;
            if (state.uid === 0) {
                state.selectedContracts = state.userContracts;
            }
        },
        setRecommendedPayment: (state, action) => {
            state.recommendedPayment = action.payload;
        },
        setSearchedContract: (state, action) => {
            state.searchedContract = action.payload.data;
        },
        setSelectedContracts: (state) => {
            state.selectedContracts = state.userContracts;
        },
        setPeriodPayment: (state, action) => {
            if (state.periodPayment !== action.payload) {
                state.periodPayment = action.payload;
            } else {
                state.periodPayment = undefined;
                state.sumPayment = state.recommendedPayment;
            }
        },
        setSumPayment: (state, action) => {
            if (state.periodPayment) {
                state.sumPayment = action.payload;
            }
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchUserInfo.fulfilled, (state, action) => {
                state.userContracts = action.payload.services;
                state.selectedContracts = action.payload.services;
            })

            .addCase(searchAddressByUid.pending, (state) => {
                state.isLoadingContract = true;
            })

            .addCase(searchAddressByUid.rejected, (state) => {
                state.isLoadingContract = false;
            })

            .addCase(searchAddressByUid.fulfilled, (state, action) => {
                state.searchedContract = action.payload?.length ? action.payload[0] : undefined;
                state.searchedContract = action.payload?.length
                    ? state.selectedContracts = action.payload
                    : undefined;
                state.selectedContracts = action.payload;
                state.isLoadingContract = false;
            });
    },
});

export const { actions: paymentFormActions, reducer: paymentFormReducer } = paymentFormSlice;
