import { createAsyncThunk } from '@reduxjs/toolkit';
import { AuthService } from 'shared/api';
import { AuthData } from '../../types/AuthData';

export const authLogin: any = createAsyncThunk<AuthData, string>(
    'auth/authLogin',
    async (phone) => {
        try {
            return await AuthService.login(phone);
        } catch (e) {
            console.log(e);
        }
    },
);
