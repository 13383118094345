import React from 'react';
import { CircularProgress } from '@mui/material';
import cls from './Loader.module.scss';

interface LoaderProps {
  className?: string;
}

export const Loader: React.FC<LoaderProps> = ({ className, ...props }) => (
    <div className={cls.Loader}>
        <CircularProgress />
    </div>
);
