import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'app/providers/StoreProvider';
import { CameraPreview } from 'shared/ui/CameraPreview';
import { dvrSelector, userStatusSelector } from 'entities/user/model/selectors';
import { IDvr } from 'entities/user/model/types';
import { Button } from 'shared/ui';
import { Arrow } from 'shared/assets/icons';
import { Loader } from 'shared/ui/Loader';
import useDeviceForCams from 'shared/hooks/useDeviceForCams/useDeviceForCams';
import { AdaptiveModal } from 'shared/ui/AdaptiveModal/AdaptiveModal';
import { ModalTopTitle } from 'widgets/ModalTopTitle';
import FullscreenIframe from 'features/FullscreenIframe/FullscreenIframe';
import cls from '../ui/DvrPage.module.scss';

const AddressCameras: React.FC = () => {
    const { address } = useParams<{ address: string }>();
    const cameras = useAppSelector(dvrSelector);
    const navigate = useNavigate();
    const [currentCamera, setCurrentCamera] = useState<IDvr | null>(null);
    const [showModal, setShowModal] = useState(false);
    const status = useAppSelector(userStatusSelector);
    const { isMobile } = useDeviceForCams();
    const addressCameras = cameras?.filter((camera) => camera.address === address);

    const onPlay = (camera: IDvr) => {
        setCurrentCamera(camera);
        setShowModal(true);
    };

    if (status === 'loading') {
        return (
            <Container sx={{ py: 2 }}>
                <Loader />
            </Container>
        );
    }

    if (!addressCameras?.length) {
        return (
            <Container>
                <p>
                    {`Камеры по адресу ${address} не найдены`}
                </p>
            </Container>
        );
    }

    return (
        <Container>
           
            <Container
                disableGutters
                sx={{
                    display: 'grid',
                    marginBottom: 3,
                    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
                    gap: 1,
                    gridAutoRows: 'minmax(0, 1fr)',
                    placeItems: 'center',
                    justifyItems: 'start',
                }}
            >
                {addressCameras?.map((camera) => (
                    <CameraPreview
                        key={camera.name}
                        className={cls.cameraPreview}
                        image={camera.previewUrl}
                        link={camera.videoUrl}
                        title={camera.title}
                        onClickPlay={() => onPlay(camera)}
                    />
                ))}
            </Container>
            <Container
                className={isMobile ? cls.addaddressForm : ''}
            >                
                {!isMobile && showModal && (
                    <AdaptiveModal
                        open={showModal && !isMobile}
                        className={cls.dvorModal}
                        onClose={() => setShowModal(false)}
                        initialFocusRef={false}
                    >
                        {currentCamera && (
                            <>
                                <Helmet><title>{currentCamera.title}</title></Helmet>
                                <ModalTopTitle
                                    title={currentCamera.title}
                                    neDvr={false}
                                    cameraName={currentCamera.title}
                                    onClose={() => setShowModal(false)}
                                    camlink={currentCamera.videoUrl}
                                />
                                <iframe
                                    title={currentCamera.title}
                                    className={cls.iframeCamera}
                                    allowFullScreen
                                    src={currentCamera.videoUrl}
                                />
                            </>
                        )}
                    </AdaptiveModal>
                )}

                {isMobile && showModal && (
                    <div className={cls.fullscreen}>
                        <div className={cls.arrowTitle}>
                            <Button
                                variant="clear"
                                onClick={() => { setShowModal(false); navigate(-1); }}
                                className={cls.titleLine}
                            >
                                <Arrow className={cls.test} />
                                {currentCamera?.address}
                            </Button>
                        </div>
                        {currentCamera && (
                            <FullscreenIframe
                                src={currentCamera.videoUrl}
                            />
                        )}
                    </div>
                )}
            </Container>
        </Container>
    );
};

export default AddressCameras;
