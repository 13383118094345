import Service from './service';
import $api from '../http';

class VerificationService extends Service {
    private phone: string;

    constructor() {
        super();
        this.phone = '';
    }

    async send(phone: string) {
        try {
            this.phone = phone;
            return await $api.post('/verification/sms', {
                action: 'send',
                phone: Number(phone),
                fingerprint: await this.getFinger(),
            });
        } catch (err: any) {
            throw new Error(err);
        }
    }

    async check(code: string, phone: string = this.phone) {
        try {
            if (!phone) return new Error('Не указан аргумент для phone');
            return await $api.post('/verification/sms', {
                action: 'check',
                phone,
                code,
                fingerprint: await this.getFinger(),
            });
        } catch (err: any) {
            throw new Error(err);
        }
    }

    async addressVerification(id: number, formData: FormData) {
        try {
            return await $api.post(`/verification/address/${id}`, formData);
        } catch (e: any) {
            throw new Error(e);
        }
    }
}

export default new VerificationService();
