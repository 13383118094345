import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider/config/StateSchema';
import { FaqArticle } from '../../types/faqSchema';

export const fetchFaqArticles = createAsyncThunk<
    FaqArticle[],
    void,
    ThunkConfig<string>
>('public/fetchArticles', async (_, { rejectWithValue, extra }) => {
    try {
        const response = await extra.api.get<{data: FaqArticle[]}>('/public/faq');

        if (!response.data) {
            return rejectWithValue('no data');
        }

        return response?.data.data;
    } catch (e: any) {
        return rejectWithValue('error');
    }
});
