import { createSelector } from '@reduxjs/toolkit';
import { StateSchema } from 'app/providers/StoreProvider';

export const variablesSelector = (state: StateSchema) => state.variables;
export const numberSelector = (state: StateSchema) => state.variables.variables?.validateCallNumber;

export const payForwardSelector = (priceTarif: number) => createSelector(
    variablesSelector,
    (variables) => {
        let payItem = variables.variables?.payForward[0];
        variables.variables?.payForward.forEach((item) => {
            if (priceTarif > item.startTarifPrice) {
                payItem = item;
            }
        });
        return payItem;
    },
);
