import axios from 'axios';
import Service from '../service';
import $api, { API_URL } from '../../http';
import { IConnectionRequest, IPaymentLink } from './types';

const instance = axios.create({
    baseURL: API_URL,
});

type Address = {
    addr_id: number;
    street: string;
    home: string;
    flat: string;
};

class PublicService extends Service {
    // получение информации при инициализации приложения
    async getInfo() {
        return await $api.get('/public/info');
    }

    // проверка адресов по нашей базе
    async checkAddress(text?: string) {
        return await $api.post('/public/check_address', { text });
    }

    async createConnectionRequest(data: IConnectionRequest) {
        return await $api.post('/public/connection_request', data);
    }

    async searchAddressByUid(uid: number) {
        return await $api.get(`/public/uid_info/${uid}`);
    }

    async getCityLocationList() {
        return await $api.get('public/locations');
    }

    async getInternetTariffList() {
        return await $api.get('public/tariffs');
    }

    async getTariffPlanList() {
        return await $api.get('public/tariffs');
    }

    async getPaymentApiUrl(gateway: string, data:IPaymentLink) {
        return await $api.post(`/public/pay/${gateway}`, data);
    }

    async sendBugReport(data: Object) {
        return await $api.post('public/bug_report', data);
    }

    async getAccidentsList(albumId: number) {
        return await $api.get(`public/dtp_archive/${albumId}`);
    }
}

export default new PublicService();
