import FingerprintJS from '@fingerprintjs/fingerprintjs';

const fpPromise = FingerprintJS.load();

export default class Service {
    async getFinger() {
        if (localStorage.getItem('fingerprint')) {
            return localStorage.getItem('fingerprint');
        }
        const fingerprint = await fpPromise;
        const result = await fingerprint.get();
        localStorage.setItem('fingerprint', result.visitorId);
        return localStorage.getItem('fingerprint');
    }
}

export const service = new Service();
