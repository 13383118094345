import { createSlice } from '@reduxjs/toolkit';
import { tariffs } from '../types/tariffs';

export interface InternetTariffCardState {
    isLoading: boolean;
    data?: tariffs;
  }

export const initialState: InternetTariffCardState = {
    isLoading: false,
    data: {
        currentTariff: {
            type: '',
            name: '',
            price: 0,
            packagePrice: 0,
            bitrate: 50000,
            ktv: true,
            mobile: true,
            mobileTariff: {
                id: 1,
                name: 'Конвергент "S"',
                inet: 10,
                minutes: 700,
                sms: 50,
                price: 300,
            },
        },
        tariffs: [],
    },
};

export const internetTariffCardSlice = createSlice({
    name: 'internetTariffCard',
    initialState,
    reducers: {
        setState: (state, action) => {
            state.data!.currentTariff = action.payload;
        },
        setTariffs: (state, action) => {
            state.data!.tariffs = action.payload;
        },
        setMobileTariff: (state, action) => {
            const { mobileTariff, name } = action.payload;
            state.data!.tariffs = state.data!.tariffs.map((tariff) => {
                if (tariff.name === name) {
                    return {
                        ...tariff,
                        mobileTariff,
                    };
                }
                return tariff;
            });

            // Если currentTariff соответствует internetTariffName, обновляем его также
            if (state.data!.currentTariff.name === name) {
                state.data!.currentTariff = {
                    ...state.data!.currentTariff,
                    mobileTariff,
                };
            }
        },
        
        setSwitch: (state, action) => {
            state.data!.tariffs = state.data!.tariffs.map((tariff) => {
                if (tariff.name === action.payload) {
                    return ({
                        ...tariff, ktv: !tariff.ktv,
                    });
                }
                return tariff;
            });
        },
        setSwitchMobile: (state, action) => {
            state.data!.tariffs = state.data!.tariffs.map((tariff) => {
                if (tariff.name === action.payload) {
                    return ({
                        ...tariff, mobile: !tariff.mobile,
                    });
                }
                return tariff;
            });
        },
    },

});

export const { actions: internetTariffCardActions, reducer: internetTariffCardReducer } = internetTariffCardSlice;
