import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { authLogin, authSelector } from 'features/Auth/model';
import { useAppDispatch } from 'app/providers/StoreProvider';
import { useLocation, useNavigate } from 'react-router-dom';

export const useDelayLogin = (timeToCall: number) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { isAuth } = useSelector(authSelector);
    const interval = useRef<number>();
    const timer = useRef<number>();
    const location = useLocation();
    const delayLogin = (phone: number) => {
        setLoading(true);

        interval.current = window.setInterval(() => {
            // авторизация
            dispatch(authLogin(phone)).then((data: any) => {
                localStorage.setItem('isLogin', '1');
                navigate(location.state?.from || '/');
            });
        }, 2000);

        timer.current = window.setTimeout(() => {
            if (!isAuth) {
                window.clearInterval(interval.current);
                setError('Время для звонка закончилось');
                setLoading(false);
            }
        }, timeToCall);
    };

    useEffect(
        () => () => {
            window.clearInterval(interval.current);
            window.clearTimeout(timer.current);
        },
        [],
    );

    const cancel = () => {
        window.clearInterval(interval.current);
        window.clearTimeout(timer.current);
        setLoading(false);
    };

    return {
        loading,
        error,
        cancel,
        delayLogin,
    };
};
