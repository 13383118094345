import React, { useState } from 'react';
import { Button, Typography } from 'shared/ui';
import { Textarea } from 'shared/ui/Textarea/Textarea';
import { useSelector } from 'react-redux';
import { currentContractSelector } from 'entities/user/model';
import { XClose } from 'shared/assets/icons';
import cls from './AddBugReportForm.module.scss';

interface Props {
    submitHandler: (text: string) => void,
    close:() => void,
}

export const AddBugReportForm: React.FC<Props> = ({ submitHandler, close }) => {
    const [textInput, setTextInput] = useState<string>('');

    const submitForm = (e: any) => {
        e.preventDefault();
        submitHandler(textInput);
        setTextInput('');
    };
    const currentContract = useSelector(currentContractSelector);

    return (
        <div className={cls.wrapper}>
            <div className={cls.titleBug}>
                <Typography variant="h3">
                    Сообщить о проблеме
                </Typography>
                <Button
                    variant="noShadow"
                    onClick={close}
                    className={cls.closeBugButton}
                >
                    <XClose />
                </Button>
            </div>
            <Typography variant="body" className={cls.heading}>
                Здесь вы можете оставить отзыв о сайте, предложить идеи по улучшению
                или сообщить о неисправности сайта.
            </Typography>

            <Typography variant="body" className={cls.heading}>

                Если возникли проблемы с
                {' '}
                <b>ИНТЕРНЕТОМ</b>
                {' '}
                или
                {' '}
                <b>ТВ</b>
                , то обратитесь в
                {' '}
                <a href="/faq">службу поддержки</a>
                {' '}
                или позвоните по номеру
                {' '}
                <a className={cls.helpPhone} href="tel:8-8172-70-02-39">8-8172-70-02-39</a>
            </Typography>
            <form className={cls.form} onSubmit={(e) => submitForm(e)}>
                <Textarea
                    placeholder="Описание проблемы:"
                    value={textInput}
                    maxLength={200}
                    onChange={(e) => setTextInput(e.target.value)}
                />
                <Button
                    className={cls.btn}
                    onClick={() => setTextInput(`Web UID:${currentContract?.uid || 'нет uid?'} ${textInput}`)}
                    disabled={!textInput}
                    type="submit"
                >
                    Отправить
                </Button>
            </form>

        </div>
    );
};
