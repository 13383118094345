import axios from 'axios';
import Service from './service';
import $api, { API_URL } from '../http';

const instance = axios.create({
    baseURL: API_URL,
});

class IntercomService extends Service {
    async getPreviewByDeviceID(deviceID: string, token: string, tm = '') {
        const { data } = await instance.get(
            `/domofon/preview?deviceID=${deviceID}&token=${token}&tm=${tm}`,
        );
        return data;
    }

    async getCallHistory() {
        try {
            const response = await $api.get('/domofon/call_history');
            return response;
        } catch (err: any) {
            throw new Error(err);
        }
    }
}

export default new IntercomService();
