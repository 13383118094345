import { createSelector } from '@reduxjs/toolkit';
import { userSelector } from '../userSelector/userSelector';
import { InetSet, MobileSet } from '../../types/IContract';

export const currentContractSelector = createSelector(
    userSelector,
    (user) => user.currentContract,
);
export const currentSpeedSelector = createSelector(
    userSelector,
    (user) => (user.currentContract?.set.find((item) => item.type === 'inet') as InetSet)
        ?.data.tarif.bitrate,
);
export const currentSimId = createSelector(
    userSelector,
    (user) => user.currentContract?.set.filter((item) => item.type === 'mobile') as MobileSet[] || 0,
);
