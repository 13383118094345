import * as yup from 'yup';

export const profileSchema = yup.object({
    firstName: yup
        .string()
        .matches(/^[^0-9]*$/, 'Поле не должно содержать цифры!'),
    lastName: yup
        .string()
        .matches(/^[^0-9]*$/, 'Поле не должно содержать цифры!'),
    middleName: yup
        .string()
        .matches(/^[^0-9]*$/, 'Поле не должно содержать цифры!'),
    phone: yup
        .string()
        .matches(
            /^89|\+79|9[0-9]*$/,
            'Поле должно быть корректным номером телефона!',
        ),
    email: yup.string().email('Поле должно быть корректной почтой!'),
});
