import { createSlice } from '@reduxjs/toolkit';
import { fetchVariables } from 'entities/variables';
import { MapState } from '../types/mapState';

const initialState: MapState = {

    data: {
        status: 'idle',
        locationData: {
            location: 'vologda',
            camerasAvailable: true,
            title: 'Вологда',
            leftTopLat: '59.27580800',
            leftTopLon: '39.80134247',
            rightBottomLat: '59.16501300',
            rightBottomLon: '40.00533300',
            center: '59.2204, 39.9033',
        },
    },
    mapCenter: {
        lat: 59.210134,
        lng: 39.878252,
    },
};

export const mapSlice = createSlice({
    name: 'map',
    initialState,
    reducers: {
        setLocation: (state, action) => {
            state.data.locationData = action.payload;
        },
        setState: (state, action) => {
            state.data.locationData = action.payload;
        },
        setMapCenter: (state, action) => {
            state.mapCenter = action.payload;
        },

    },
    extraReducers(builder) {
        builder
            .addCase(fetchVariables.pending, (state) => {
                state.data.status = 'loading';
            })
            .addCase(fetchVariables.fulfilled, (state, action) => {
                if (action.payload.locations) {
                    const locationData = action.payload.locations.find(
                        (item) => item.location === state.data.locationData.location,
                    );
                    state.data.locationData = { ...state.data.locationData, ...locationData };
                }
                state.data.status = 'succeeded';
            })
            .addCase(fetchVariables.rejected, (state) => {
                state.data.status = 'failed';
            });
    },
});

export const { actions: mapActions, reducer: mapReducer } = mapSlice;
