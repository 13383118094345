import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { titleSelector, variablesActions } from 'entities/variables/model';
import { useAppDispatch } from 'app/providers/StoreProvider';

const patterns: Record<string, string> = {
    login: 'Авторизация',
    history: 'История платежей',
    user: 'Профиль',
    intercom: 'Мой домофон',
    map: 'Карта',
    dvr: 'Мой двор',
    tariff: 'Мои тарифы',
    bonus: 'Бонусы',
    faq: 'Поддержка',
    mobile: 'Мобильная связь',
    requests: 'История запросов',
    internet: 'Интернет и ТВ',
    calls: 'История звонков',
    uk: 'Связь с УК',
    payment: 'Оплата услуг',
    pay: 'Оплата услуг',
    payhistory: 'История платежей',
    promotion: 'Акции',
    entrances: 'Другие подъезды',
    constructor: 'Интернет и ТВ',
    changemobile: 'Изменить тариф',
    accmanage: 'Управление ЛС',

};
export function getTitle(pathname: string): any {
    return patterns[pathname] ? patterns[pathname] : 'Личный кабинет';
}
export const useTitle = () => {
    const dispatch = useAppDispatch();
    const title = useSelector(titleSelector);
    const location = useLocation();
    useEffect(() => {
        const string = location.pathname?.match(/[a-z-]/g)?.join('') || 'Личный кабинет';
        const title = getTitle(string);
        dispatch(variablesActions.setTitle(title));
    }, [window.location.pathname]);
    return title;
};
