import React, { useRef } from 'react';

const FullscreenIframe: React.FC<{ src: string }> = ({ src }) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);

    // Function to request fullscreen
    const requestFullscreen = (element: any) => {
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) { /* Firefox */
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) { /* IE/Edge */
            element.msRequestFullscreen();
        }
    };

    // Handle click event on iframe to request fullscreen
    const handleIframeClick = () => {
        if (iframeRef.current) {
            requestFullscreen(iframeRef.current);
        }
    };

    return (
        <iframe
            title="cameras"
            ref={iframeRef}
            src={src}
            onClick={handleIframeClick}
        />
    );
};

export default FullscreenIframe;
