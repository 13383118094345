import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { StoreProvider } from 'app/providers/StoreProvider';
import * as Sentry from '@sentry/react';
import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';
import { App } from './app';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { PageError } from './widgets/PageError';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://35ec6645f4924c96908bc01eac8e33fc@sentry.baza.net/12',
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/user\.baza\.net\//],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

const queryClient = new QueryClient();

root.render(
    <StoreProvider>
        <BrowserRouter>
            <Sentry.ErrorBoundary fallback={<PageError />}>
                <QueryClientProvider client={queryClient}>
                    <App />
                </QueryClientProvider>
            </Sentry.ErrorBoundary>
        </BrowserRouter>
    </StoreProvider>,
);

if (process.env.NODE_ENV === 'production') {
    serviceWorkerRegistration.register();
} else {
    serviceWorkerRegistration.unregister();
}
