import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { NavigationBar } from 'widgets/NavigationBar';
import { BottomBar } from 'widgets/BottomBar';
import { profileSelector } from 'entities/user/model';
import { authSelector } from 'features/Auth/model';
import { PhoneAssociateInfo } from 'widgets/PhoneAssociateInfo';
import { useAppDispatch } from 'app/providers/StoreProvider';
import { fetchAdditionalAddresses } from 'entities/additionalAddress';
import useDeviceDetect from 'shared/hooks/useDeviceDetect/useDeviceDetect';
import { InferType } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { ToastContainer } from 'react-toastify';
import { getTitle } from 'app/hooks/useTitle';
import { profileSchema } from 'features/EditableProfileCard';
import cls from './MainLayout.module.scss';

type FormValues = InferType<typeof profileSchema>;

const MainLayout = () => {
    const dispatch = useAppDispatch();
    const { isAuth, token } = useSelector(authSelector);
    const profile = useSelector(profileSelector);
    const [title, setTitle] = useState('');
    const { isMobile } = useDeviceDetect();
    const needAssociate = profile?.needAssociatePhoneToServices;
    // eslint-disable-next-line no-unused-vars
    const { register } = useForm<FormValues>({
        defaultValues: profile,
        mode: 'onBlur',
        resolver: yupResolver(profileSchema),
    });

    useEffect(() => {
        const title = getTitle(
            window.location.pathname?.match(/[a-z]/g)?.join('') || 'Личный кабинет',
        );
        setTitle(title);
    }, [window.location.pathname]);

    const showName = useCallback(
        () => {
            if (profile?.firstName) {
                return profile?.firstName;
            }
            return 'Гость';
        },
        [profile, title],
    );

    useEffect(() => {
        if ((token !== 'empty') && isAuth) {
            dispatch(fetchAdditionalAddresses());
        }
    }, [dispatch, isAuth, token]);

    const getInfoBlock = () => needAssociate && title === 'Личный кабинет' && <PhoneAssociateInfo />;

    return (
        <div className={cls.MainLayout}>

            <NavigationBar userName={showName()} isMobile={isMobile} title={title} />
            <div className={cls.wrapper}>
                <Outlet />
            </div>

            {isMobile
                ? (
                    <>
                        <div className={cls.bottomSign}>
                            {getInfoBlock()}
                        </div>
                        {isAuth && <BottomBar />}
                    </>
                )
                : (
                    <>
                        {getInfoBlock()}
                    </>
                )}
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
};
export default MainLayout;
