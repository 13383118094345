import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '../../../../../app/providers/StoreProvider/config/StateSchema';
import { fetchUserInfo } from '../fetchUserInfo/fetchUserInfo';
import { TariffChangeOption } from '../../types/ITariff';

interface ChangeTariffResponse {
    result: boolean;
    payDate: string;
    nextTariff: TariffChangeOption;
}

interface ChangeTariffArgs {
    nextTarifID: number;
    oper: string;
    uid: number;
}
export const changeContractTariff = createAsyncThunk<ChangeTariffResponse, ChangeTariffArgs, ThunkConfig<string>>(
    'user/changeContractTariff',
    async (args, { dispatch, extra, rejectWithValue }) => {
        const { uid, oper, nextTarifID } = args;
        try {
            const response = await extra.api.post<{data: ChangeTariffResponse}>('/user/service', {
                nextTarifID,
                oper,
                uid,
            });

            if (!response.data.data) {
                return rejectWithValue('no data');
            }

            dispatch(fetchUserInfo());

            return response.data.data;
        } catch (e) {
            return rejectWithValue('error');
        }
    },
);
