import { createSlice } from '@reduxjs/toolkit';
import { MapPageSchema } from 'pages/MapPage';

const initialState: MapPageSchema = {
    officeMarker: undefined,
    cityCamMarker: undefined,
    isModalOpen: false,
};
const mapPageSlice = createSlice({
    name: 'mapPage',
    initialState,
    reducers: {
        setOfficeMarker: (state, action) => {
            state.officeMarker = action.payload;
        },
        setCityCamMarker: (state, action) => {
            state.cityCamMarker = action.payload;
        },
        setIsModalOpen: (state, action) => {
            state.isModalOpen = action.payload;
        },
        setIsCityModalOpen: (state, action) => {
            state.isModalOpen = action.payload;
        },
    },
});

export const { actions: mapPageActions, reducer: mapPageReducer } = mapPageSlice;
