import React from 'react';

const Timer = ({
    minutes = 0,
    seconds = 0,
    setTimeExp,
}: {
  minutes: number;
  seconds: number;
  setTimeExp: Function;
}) => {
    // const [paused, setPaused] = React.useState(false);
    const [over, setOver] = React.useState(false);
    const [[m, s], setTime] = React.useState([minutes, seconds]);

    const tick = () => {
    // if (paused || over) return;
        if (over) return;

        if (m === 0 && s === 0) {
            setOver(true);
            setTimeExp(true);
        } else if (s === 0) {
            setTime([m - 1, 59]);
        } else {
            setTime([m, s - 1]);
        }
    };

    React.useEffect(() => {
        const timerID = setInterval(() => tick(), 1000);
        return () => clearInterval(timerID);
    });

    return (
        <span>
            {`${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`}
        </span>
    );
};

export default Timer;
