import { createAsyncThunk } from '@reduxjs/toolkit';
import { PublicService } from 'shared/api';

export const searchAddressByUid: any = createAsyncThunk(
    'payments/searchAddressByUid',
    async (uid: number, { rejectWithValue, dispatch }) => {
        try {
            const response = await PublicService.searchAddressByUid(uid);

            return response.data.data;
        } catch (e: any) {
            throw new Error(e);
        }
    },
);
