import { createAsyncThunk } from '@reduxjs/toolkit';
import AuthService from 'shared/api/baza/auth/auth.service';

export const authLogout = createAsyncThunk('auth/logout', async () => {
    try {
        const response: any = await AuthService.logout();
        return { ...response };
    } catch (e) {
        console.log(e);
    }
});
