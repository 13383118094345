import { createAsyncThunk } from '@reduxjs/toolkit';
import PublicService from 'shared/api/baza/public/public.service';
import { InternetTariffState } from '../types/InternetTariffState';

export const fetchInternetTariff = createAsyncThunk<InternetTariffState>(
    'InternetTariff/model/services/fetchInternetTariff',
    async () => {
        try {
            const response = await PublicService.getInternetTariffList();

            return { ...response?.data };
        } catch (e: any) {
            return e.message;
        }
    },
);
