import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FaqArticle, FaqSchema } from '../types/faqSchema';
import { fetchFaqArticles } from '../services/fetchFaqArticles/fetchFaqArticles';

const initialState: FaqSchema = {
    data: [],
    isLoading: false,
    error: undefined,
};
const faqSlice = createSlice({
    name: 'faq',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchFaqArticles.pending, (state) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(fetchFaqArticles.fulfilled, (state, action: PayloadAction<FaqArticle[]>) => {
                state.data = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchFaqArticles.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export const { actions: faqActions, reducer: faqReducer } = faqSlice;
