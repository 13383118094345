import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider/config/StateSchema';
import { PublicService } from 'shared/api';
import { AccidentsArticle } from '../../types/accidentsSchema';

export const fetchAccidents = createAsyncThunk<
    AccidentsArticle[],
    number,
    ThunkConfig<string>
>(
    'accidents/fetchAccidents',
    async (albumId, { rejectWithValue }) => {
        try {
            const response = await PublicService.getAccidentsList(albumId);

            if (!response.data) {
                return rejectWithValue('no data');
            }

            return response?.data.data;
        } catch (e: any) {
            return rejectWithValue('error');
        }
    },
);
