import { createAsyncThunk } from '@reduxjs/toolkit';
import PublicService from 'shared/api/baza/public/public.service';
import { LocationState } from '../types/LocationState';

export const fetchLocations = createAsyncThunk<LocationState>(
    'location/model/services/fetchLocations',
    async () => {
        try {
            const response = await PublicService.getCityLocationList();

            return { ...response?.data };
        } catch (e: any) {
            return e.message;
        }
    },
);
