import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input, Typography } from 'shared/ui';
import { InferType } from 'yup';
import { profileSchema } from '../model/schema/profileSchema';
import cls from './EditableProfileCard.module.scss';

type FormValues = InferType<typeof profileSchema>;

interface EmailUpdateFormProps {
    profileEmail: string | undefined;
    onSaveClick: (data: FormValues) => void;
    onClose: () => void;
    isOpen: boolean;
}

export const EmailUpdateForm: FC<EmailUpdateFormProps> = ({
    profileEmail,
    onSaveClick,
    onClose,
    isOpen,
}) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitted },
    } = useForm<FormValues>({
        defaultValues: { email: profileEmail },
        mode: 'onSubmit',
        resolver: yupResolver(profileSchema),
    });

    return (
        <form onSubmit={handleSubmit(onSaveClick)}>
            <Typography gutterBottom variant="body">
                Привяжите электронную почту для отправки чеков и документов.
            </Typography>
            <Input
                fullwidth
                placeholder="E-mail"
                variant="outlined"
                {...register('email')}
            />
            <div className={cls.errorContainer}>
                {errors.email && (
                    <span className={cls.errorText}>{errors.email.message}</span>
                )}
            </div>
            <div className={cls.emailBtns}>
                <Button className={cls.editBtn} type="submit">
                    Сохранить
                </Button>
            </div>
        </form>
    );
};
