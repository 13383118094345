import { Box, Container } from '@mui/material';
import { Typography } from 'shared/ui';
// @ts-ignore
const NotFoundPage = () => (
    <Container
        sx={{
            background: 'linear-gradient(180deg, #E4EDF9 0%, #FCFDFF 100%)',
            py: 2,
            height: '100vh',
        }}
    >
        <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h3">404 Не найдено</Typography>
            <img width="300px" src="/images/see.png" alt="" />
        </Box>
    </Container>
);

export default NotFoundPage;
