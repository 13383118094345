import { createSelector } from '@reduxjs/toolkit';
import { getAddresses } from 'entities/additionalAddress/model/slice/additionalAddressSlice/additionalAddressSlice';

export const getNonVerifiedAddress = createSelector(
    getAddresses.selectAll,
    (addresses) => addresses?.find(
        (address) => address?.verificationStatus !== 'VERIFIED',
    ),
);
export const getAllAddAddress = createSelector(
    getAddresses.selectAll,
    (addresses) => addresses,
);
export const getWaitingAddress = createSelector(
    getAddresses.selectAll,
    (addresses) => addresses?.filter(
        (address) => address?.verificationStatus === 'WAITING',
    ),
);
export const getDenyAddress = createSelector(
    getAddresses.selectAll,
    (addresses) => addresses?.filter(
        (address) => address?.verificationStatus === 'DENY',
    ),
);
