import { createSelector } from '@reduxjs/toolkit';
import { CityMarkerType } from 'entities/variables/model/types/ICityCams';
import { variablesSelector } from '../variablesSelector/variablesSelector';

export const cameraByNameSelector = (name: string) => createSelector(
    variablesSelector,
    (variables) => variables.variables?.mapMarkers?.cityCams?.markers?.find(
        (marker: CityMarkerType) => marker?.additional?.cameraName === name,
    ),
);
