import React, { useState } from 'react';
import { Button, Card } from 'shared/ui';
import { AdaptiveModal } from 'shared/ui/AdaptiveModal/AdaptiveModal';
import { AddServiceForm } from 'features/AddServiceForm';
import cls from './ConnectToInternet.module.scss';

export const ConnectToInternet: React.FC = () => {
    const [showDrawer, setShowDrawer] = useState(false);
    const onClickInternet = () => {
        setShowDrawer(true);
    };
    return (
        <>
            <Card className={cls.cardInetTv}>
                <div className={cls.cardInetTv_title}>Подключить услуги и сервисы Baza.net</div>
                <div className={cls.cardInetTv_text}>Работай, учись, отдыхай</div>
                <Button
                    className={cls.cardInetTv_button}
                    onClick={() => onClickInternet()}
                >
                    Оставить заявку
                </Button>
            </Card>
            <AdaptiveModal
                initialFocusRef={false}
                open={showDrawer}
                scrollLocking
                onClose={() => setShowDrawer(false)}
            >
                <AddServiceForm />
            </AdaptiveModal>
        </>
    );
};
