class LocalStorage {
    storage: string;

    constructor(storage: string) {
        this.storage = storage;
    }

    get() {
        return localStorage.getItem(this.storage);
    }

    remove() {
        return localStorage.removeItem(this.storage);
    }

    set(item: string) {
        return localStorage.setItem(this.storage, item);
    }
}

export default LocalStorage;
