import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { IContract } from 'entities/user/model/types';
import {
    Button, Card, Flex, Typography,
} from 'shared/ui';
import { userActions } from 'entities/user/model';
import {
    ArrowForward, InternetIcon, MobileIcon, TvIcon,
} from 'shared/assets/icons';
import { AdaptiveModal } from 'shared/ui/AdaptiveModal/AdaptiveModal';
import { AddServiceForm } from 'features/AddServiceForm';
import { AdditionalServicesBlock } from 'features/AdditionalServicesBlock';
import { AddAdditionalUidForm } from 'features/AddAdditionalUidForm';
import cls from './AccountList.module.scss';

interface AccountListProps {
    isMobile: boolean;
    contracts: any;
}

export const AccountList: React.FC<AccountListProps> = ({
    isMobile,
    contracts,
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [comment, setComment] = useState('');

    const [showDrawer, setShowDrawer] = useState(false);
    const onClickModal = (comment: string) => {
        setComment(comment);
        setShowDrawer(true);
    };

    const dispatchContract = (contract: IContract) => {
        dispatch(userActions.setCurrentContract(contract));
    };

    const goToAccmanage = (contract: IContract) => () => {
        dispatchContract(contract);
        navigate('/accmanage');
    };

    const goToPayment = (contract: IContract) => () => {
        dispatchContract(contract);
        navigate('/payment');
    };

    const hasService = (contract: any, type: any): boolean => contract.set.some((item: any) => item.type === type);

    return (
        <>
            {contracts.length > 0 ? (
                <div className={cls.accountList}>
                    {contracts?.map((contract: IContract) => (
                        !isMobile ? (
                            <Card
                                className={cls.accCard}
                                key={contract.uid}
                            >
                                <Typography variant="h3" gutterBottom>
                                    Договор №
                                    {contract.uid}
                                </Typography>
                                <Typography variant="body">
                                    {contract.addressFull}
                                </Typography>
                                <div className={cls.balancePay}>
                                    <div>
                                        <p>Баланс</p>
                                        <div className={cls.rubBalance}>
                                            {Math.round(Number(contract.deposit || 0))}
                                            {' '}
                                            <span>&#8381;</span>
                                        </div>
                                    </div>
                                    <div>
                                        <p>К оплате</p>
                                        <div className={cls.rubBalance}>
                                            {contract.recommendedPayment}
                                            {' '}
                                            <span>&#8381;</span>
                                        </div>
                                        {' '}

                                    </div>
                                </div>
                                <div>
                                    <div className={cls.addTitle}>Подключенные услуги:</div>
                                    <div
                                        className={cls.addServices}
                                    >
                                        <AdditionalServicesBlock
                                            onClickModal={onClickModal}
                                            contract={contract}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={cls.buttonContainer}
                                >
                                    <Button
                                        className={cls.ruleButton}
                                        onClick={goToAccmanage(contract)}
                                    >
                                        Управление лицевым счетом
                                    </Button>
                                </div>
                            </Card>
                        ) : (
                            <Card
                                className={cls.accCard_mobile}
                                key={contract.uid}
                            >
                                <Flex justify="space-between">
                                    <div className={cls.dogovorMobile}>
                                        Договор №
                                        {contract.uid}
                                    </div>

                                    <div
                                        className={cls.buttonToGeneral}
                                        onClick={goToAccmanage(contract)}
                                    >
                                        <ArrowForward />
                                    </div>
                                </Flex>
                                <div
                                    className={cls.addServices}
                                >
                                    <div className={cls.additionalServices}>
                                        <Flex direction="row" gap={10}>
                                            <div className={cls.mobileList}>
                                                {hasService(contract, 'inet') && (
                                                    <InternetIcon />
                                                )}
                                                {hasService(contract, 'ktv') && (
                                                    <TvIcon />
                                                )}
                                                {hasService(contract, 'mobile') && (
                                                    <MobileIcon />
                                                )}

                                            </div>
                                        </Flex>
                                    </div>
                                    <Typography className={cls.addressFull} variant="body">
                                        {contract.addressFull}
                                    </Typography>
                                </div>
                                <div className={cls.mobilePay}>
                                    <div>
                                        <p>Баланс</p>
                                        <div className={cls.rubBalance}>
                                            {Math.round(Number(contract.deposit || 0))}
                                            {' '}
                                            <span>&#8381;</span>
                                        </div>
                                    </div>
                                    {/* <div>
                                    <p>К оплате</p>
                                    <div className={cls.rubBalance}>
                                        {contract.recommendedPayment}
                                        {' '}
                                        <span>&#8381;</span>
                                    </div>
                                    {' '}

                                </div> */}
                                    <Button
                                        className={cls.payButton}
                                        onClick={goToPayment(contract)}
                                    >
                                        Пополнить
                                    </Button>
                                </div>
                            </Card>
                        )
                    ))}
                </div>
            ) : (
                <Card
                    className={cls.addContract}
                >
                    <AddAdditionalUidForm />
                </Card>
            )}

            <AdaptiveModal
                initialFocusRef={false}
                open={showDrawer}
                onClose={() => setShowDrawer(false)}
            >
                <div>
                    <AddServiceForm comment={comment} />
                </div>
            </AdaptiveModal>
        </>
    );
};
