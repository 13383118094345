import axios from 'axios';
import $api, { API_BAZA } from '../../http';
import Service from '../service';

class AuthService extends Service {
    async login(phone: any) {
        const response = await axios.post(
            '/auth/login',
            {
                phone,
                fingerprint: await this.getFinger(),
            },
            {
                withCredentials: true,
                baseURL: API_BAZA,
            },
        );

        return response.data;
    }

    async ipAuth() {
        const response = await axios.post(
            '/auth/ip_authorization',
            {
                fingerprint: await this.getFinger(),
            },
            {
                withCredentials: true,
                baseURL: API_BAZA,
            },
        );

        return response.data;
    }

    async logout() {
        await $api.post('/auth/logout', {
            fingerprint: await this.getFinger(),
        });
    }

    async refresh() {
        return await axios
            .create({
                withCredentials: true,
                baseURL: API_BAZA,
            })
            .post('/auth/refresh_token', {
                fingerprint: await this.getFinger(),
            });
    }
}

export default new AuthService();
