import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authSelector } from 'features/Auth/model';

const ProtectedRoutes = () => {
    const { isAuth } = useSelector(authSelector);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!isAuth) {
            navigate('/login', { state: { from: location } });
        }
    }, [isAuth, navigate, location]);

    return <Outlet />;
};

export default ProtectedRoutes;
