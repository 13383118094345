import { createAsyncThunk } from '@reduxjs/toolkit';
import VerificationService from 'shared/api/baza/verification.service';

export const verifyPhone: any = createAsyncThunk(
    'Verification/verifyPhone',
    async (args: { code: string }, { rejectWithValue, dispatch }) => {
        try {
            const { code } = args;
            const response = await VerificationService.check(code);

            return { code };
        } catch (e: any) {
            throw new Error(e);
        }
    },
);
