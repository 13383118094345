import React, { memo } from 'react';
import { classNames } from 'shared/lib';
import cls from './Typography.module.scss';

interface TypographyProps extends React.ComponentProps<'p'> {
  variant: 'h1' | 'h2' | 'h3' | 'bold' | 'body';
  size?: 'size_s' | 'size_m' | 'size_l';
  gutterBottom?: boolean;
  align?: 'center' | 'left' | 'right'
  className?: string;
}
const Typography = memo(({
    variant, className, size, gutterBottom, align = 'left', ...props
}: TypographyProps) => {
    const additional = [cls[variant], cls[align], className];
    if (size) {
        additional.push(cls[size]);
    }
    return (
        <p className={classNames(cls.Typography, { [cls.gutterBottom]: gutterBottom }, additional)} {...props} />
    );
});

export default Typography;
